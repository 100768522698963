const Checkbox = ({checkedInner=null, handleCheckboxInner, text=null, textLeft=false}) => {
    return (
        <div className="flex gap-1 p-1 h-8 ">
            {textLeft ? <p>{text}</p> : null}

            <label className="checkbox style-e flex flex-col ">
                <input className="hidden" type='checkbox' name='matrix_years' 
                    checked={checkedInner}
                    onChange={handleCheckboxInner} 
                    />
                <div className="checkbox__checkmark"></div>
            </label>

            {!textLeft ? <p>{text}</p> : null}
        </div>
    )
}

export default Checkbox;