import {createSlice} from '@reduxjs/toolkit';
import { formattedDateWithoutClockReversed } from '../little_components/services';

const historyTimeSlice = createSlice ({
    name: 'historyTime',
    initialState: {
        historyTime: formattedDateWithoutClockReversed(new Date()),
    },
    reducers: {
        updateHistoryTime(state, action) {
            // console.log(state,action)
            state.historyTime= action.payload
        }
    }
})

export const {updateHistoryTime} = historyTimeSlice.actions; 

export default historyTimeSlice.reducer;