import { useDispatch } from "react-redux"
import { useState, useEffect } from "react";
import TitleChange from "../title_change"
import throttle from 'lodash/throttle';
import { t } from "i18next";
import DropDownSVG from "../../../svg/dropDown_svg";
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import { moonPosition } from "../calculations/zodiac_calculations";

export const SolarSystem = ({ moonInfo, setMoonInfo, cityLatLong, prevDeltaX, setPrevDeltaX, moonDate, setMoonDate, setTodayDate }) => {
    const dispatch = useDispatch()

    // const [windowWidth, setWindowWidth] = useState(`scale-[${Math.trunc(window.innerWidth / 800 * 100)}%]`);

    // useEffect(() => {
    //     const handleResize = () => {
    //         setWindowWidth(`scale-[${Math.trunc(window.innerWidth / 800 * 100)}%]`);
    //     };

    //     window.addEventListener('resize', handleResize);
    //     // Удаление обработчика при размонтировании компонента
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    const handleThrottledSwipeMove = throttle((event) => {
        setTodayDate(false)

        let moveX
        let deltaX
        let finalDeltaX

        dispatch(updateDisplayNonOverflow(true))



        if (event.type === "touchmove") {
            moveX = event.touches[0].clientX;

            finalDeltaX = Math.floor((moveX) / 5);

            if (prevDeltaX > finalDeltaX) {
                deltaX = -500
            } else if (prevDeltaX < finalDeltaX) {
                deltaX = 500
            } else if (prevDeltaX === finalDeltaX) {
                deltaX = 0
            }

            setPrevDeltaX(finalDeltaX)
        }

        let currentDate = new Date(moonDate);
        setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX));
        setMoonInfo(moonPosition(currentDate, cityLatLong));


    }, 2000);


    const handleSwipeStart = (event) => {

        setTodayDate(false)

        const startX = event.clientX

        const handleSwipeMove = (event) => {
            let moveX
            let deltaX

            if (event.type === "mousemove") {
                moveX = event.clientX;
                deltaX = Math.floor((moveX - startX));

                let currentDate = new Date(moonDate);
                setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX * 100))
                setMoonInfo(moonPosition(currentDate, cityLatLong))
            }
        };

        const handleSwipeEnd = () => {
            // Очищаем обработчики событий после окончания перетаскивания
            window.removeEventListener('mousemove', handleSwipeMove);
            window.removeEventListener('mouseup', handleSwipeEnd);
        };

        // Добавляем обработчики событий для перемещения мыши и окончания перетаскивания
        window.addEventListener('mousemove', handleSwipeMove);
        window.addEventListener('mouseup', handleSwipeEnd);
    };

    return (
        <>
            <TitleChange
                rusTitle="Солнечная система онлайн"
                engTitle="Solar system online"
                rusDescription="Интерактивная модель солнечной системы"
                engDescription="Interactive model of the solar system"
                ruskeyWords='Solar system, zodiac, planets, start'
                engkeyWords='Солнечная система, зодиак, звёзды'
            />


            <div className={`flex items-center justify-center scale-[45%] lg:scale-90 rotate-180`}>
                <div className="flex items-center justify-center rotate-90 z-[10] p-4"
                    onMouseDown={(e) => handleSwipeStart(e)}
                    onTouchMove={handleThrottledSwipeMove}
                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                >
                    <div className="flex items-center justify-center flex-col cursor-pointer">
                        <DropDownSVG className='animate-pulse' />
                        <DropDownSVG className='w-4 h-4 animate-pulse' />
                        <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                    </div>
                </div>

                <div className="w-[900px] h-[900px] sm:h-[450px] relative flex items-center justify-center cursor-pointer "

                >


                    <div className="w-full h-full flex items-center justify-center z-[1]">



                        {/* {console.log(moonInfo[23], moonInfo[24])} */}

                        {/* Центр масс Солнца */}
                        <div className="absolute w-10 h-10 outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-sun')} className="z-[13] cursor-pointer rounded-full rotate-[180deg] absolute h-[50px] w-[50px] title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Солнце выдвинуто от центра */}
                        <div className="relative h-[50px] w-[50px] z-[1]"
                            style={{ 'transform': `rotate(${-(moonInfo[23] - moonInfo[24])}deg` }}

                        // onMouseDown={(e) => handleSwipeStart(e)}
                        // onTouchMove={handleThrottledSwipeMove}
                        // onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(-10px)` }}
                                className=""
                            >
                                <img
                                    style={{ 'transform': `rotate(${-moonInfo[19] * 2}deg` }}
                                    className="pointer-events-none" src="/images/planets/sun/sun.png" alt='sun'></img>

                            </div>
                        </div>

                        {/* Орбита Меркурия */}
                        <div className="absolute w-[80px] h-[80px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-mercury')} className="z-[12] cursor-pointer rounded-full rotate-[180deg] absolute h-[100px] w-[100px] title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Меркурий */}
                        <div className="absolute  z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[19]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(40px)` }}
                                className="rounded-full overflow-hidden"
                            >

                                <img src="/images/planets/mercury/mercury.png" alt='earth' className="pointer-events-none h-[15px] w-[15px]"></img>
                            </div>
                        </div>

                        {/* Орбита Венеры */}
                        <div className="absolute w-[140px] h-[140px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-venus')} className="z-[11] cursor-pointer rounded-full rotate-[180deg] absolute h-[160px] w-[160px] title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Venus */}
                        <div className="absolute z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[20]}deg` }}

                        // style={{ 'transform': `translateX(50px)` }}


                        >
                            <div
                                style={{ 'transform': `translateX(70px)` }}
                                className="flex items-center justify-center"
                            >

                                <img src="/images/planets/venus/venus.png" alt='earth' className="h-[25px] w-[25px] pointer-events-none  rounded-full"></img>
                            </div>
                        </div>




                        {/* Орбита Земли */}
                        <div className="absolute w-[220px] h-[220px]  outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-earth')} className="z-[10] h-[250px] w-[250px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Земля */}
                        {/* {console.log(moonInfo[18])} */}
                        <div className="absolute z-[1]"

                            // Кружение от солнца
                            style={{ 'transform': `rotate(${-moonInfo[18]}deg ` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >

                            {/* солнце меркурий венера земля марс юпитер сатурн */}
                            {/* // 28 29 30 31 32 33 34 35 36 37 */}
                            {/* DELETE THIS! */}
                            {false
                                ?
                                <div className="relative pr-[800px]">

                                    <div className="absolute">
                                        {/* Линия к Солнцу */}
                                        <div className="w-[1000px] h-[1px] border-t-[1px] border-dashed"
                                            style={{ 'transform': `rotate(${-moonInfo[28]}deg ` }}
                                        >
                                        </div>
                                    </div>

                                    <div className="absolute">
                                        {/* Линия к Меркурию */}
                                        <div className=" w-[1000px] h-[1px] border-[1px] border-dashed "
                                            style={{ 'transform': `rotate(${moonInfo[18] - moonInfo[29]}deg ` }}
                                        >
                                        </div>
                                    </div>

                                    <div className="absolute">
                                        {/* Линия к Венера */}
                                        <div className=" w-[1000px] h-[1px] border-[1px] border-dashed "
                                            style={{ 'transform': `rotate(${moonInfo[18] - moonInfo[30]}deg ` }}
                                        >
                                        </div>
                                    </div>

                                    <div className="absolute">
                                        {/* Линия к Марс */}
                                        <div className=" w-[1000px] h-[1px] border-[1px] border-dashed "
                                            style={{ 'transform': `rotate(${moonInfo[18] - moonInfo[32]}deg ` }}
                                        >
                                        </div>
                                    </div>

                                </div>
                                : null
                            }


                            {/* Выдвинуто от Солнца */}
                            <div
                                style={{ 'transform': `translateX(110px)` }}
                                className="relative flex items-center justify-center"
                            >
                                {/* Орбита Луны */}
                                <div className="absolute w-[50px] h-[50px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                                {/* Луна */}
                                <div className="w-[8px] h-[8px] absolute z-[2]"
                                    style={{ 'transform': `rotate(${moonInfo[11] + 95}deg)` }}
                                    // style={{ 'transform': `translateX(${60 + Math.floor((moonInfo[4] * 1000) / 1000 - 356400) / 1000}px)` }}
                                    onMouseDown={(e) => handleSwipeStart(e)}
                                    onTouchMove={handleThrottledSwipeMove}
                                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                                >

                                    <div
                                        // кружение вокруг земли
                                        style={{ 'transform': `translateX(25px)` }}
                                        className="absolute top-0 left-0 h-[8px] w-[8px]"

                                    >

                                        {/* MoonShadow */}
                                        {/* <div style={{ 'boxShadow': `inset 0px 10px 5px 2px rgba(0, 0, 0, 0.9)`, 'transform': `rotate(${-(moonInfo[12] + moonInfo[11]) + moonInfo[12]}deg)` }} className="  w-[8px] h-[8px] absolute rounded-full z-[1]"></div> */}

                                        <img src="/images/planets/moon/moon.png" alt='moon_near_earth' className="rounded-full pointer-events-none"></img>
                                    </div>
                                </div>

                                {/* Точка город */}
                                {/* <div className="absolute top-[50px] left-[40px] -rotate-[30deg] w-[5px] h-[5px] bg-slate-300 z-[2] rounded-full"></div> */}

                                {/* Линия Видимости */}
                                {/* <div className="z-0 absolute top-[80px] -left-[50px] -rotate-[40deg] w-[100px] outline-dashed outline-[1px] outline-slate-500 bg-black"></div> */}
                                {/* <div className="z-0 absolute top-[15px] left-[50px] -rotate-[25deg] w-[100px] outline-dashed outline-[1px] outline-slate-500 bg-black"></div> */}

                                {/* EarthShadow */}
                                {/* <div style={{ 'boxShadow': `inset 0px 50px 20px 2px rgba(0, 0, 0, 0.9)`, 'transform': `rotate(${-30 + moonInfo[12]}deg)` }} className="  w-[80px] h-[80px] absolute rounded-full z-[1]"></div> */}

                                {/* Центр масс Земли*/}
                                <div
                                    className="absolute"
                                    style={{ 'transform': `rotate(${moonInfo[11] + 95}deg)` }}>

                                    <div
                                        style={{ 'transform': `translateX(0px) ` }}
                                    >
                                        <div
                                            // style={{'transform': `rotate(${moonInfo[11] + 95}deg)`}}
                                            className="w-[25px] h-[25px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate"
                                        ></div>
                                    </div>
                                </div>

                                {/* Земля */}
                                <div
                                    className="absolute h-[30px] w-[30px]"
                                    style={{ 'transform': `rotate(${moonInfo[11] - 95}deg)` }}
                                >
                                    {/* Кружение земли вокруг оси */}
                                    <div
                                        style={{ 'transform': `translateX(7px) rotate(${-moonInfo[12]}deg) ` }}
                                    >
                                        <img src="/images/planets/earth/earth_from_top.png" alt='earth' className="pointer-events-none rotate-[120deg] border-slate-500 border-[1px] rounded-full"></img>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Орбита Марса */}
                        <div className="absolute w-[300px] h-[300px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-mars')} className="z-[9] h-[320px] w-[320px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* mars */}
                        <div className="absolute h-[20px] w-[20px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[21]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(150px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/mars/mars.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита asteroids*/}
                        <div className="absolute w-[330px] h-[330px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                        {/* Орбита asteroids*/}
                        <div className="absolute w-[310px] h-[310px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* Cerera */}

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-cerera')} className="z-[8] h-[380px] w-[380px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        <div className="absolute w-[350px] h-[350px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* Орбита asteroids */}
                        <div className="absolute w-[360px] h-[360px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                        {/* Орбита asteroids */}
                        <div className="absolute w-[370px] h-[370px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* asteroids Cerera */}
                        <div className="absolute h-[10px] w-[10px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[22]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(175px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/mars/mars.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита Юпитера */}
                        <div className="absolute w-[440px] h-[440px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-jupiter')} className="z-[7] h-[470px] w-[470px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* jupiter */}
                        <div className="absolute h-[40px] w-[40px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[23]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(220px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/jupiter/jupiter2.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита Сатурна */}
                        <div className="absolute w-[540px] h-[540px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-saturn')} className="z-[6] h-[360px] w-[360px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* saturn */}
                        <div className="absolute h-[35px] w-[35px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[24]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >

                            <div
                                style={{ 'transform': `translateX(260px)` }}
                                className="relative flex items-center justify-center"
                            >

                                {/* saturn Rings */}
                                <div className="absolute w-[40px] h-[40px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                                {/* saturn Rings */}
                                <div className="absolute w-[50px] h-[50px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                                {/* saturn Rings */}
                                <div className="absolute w-[55px] h-[55px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                                {/* saturn Rings */}
                                <div className="absolute w-[65px] h-[65px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                                {/* saturn Rings */}
                                <div className="absolute w-[70px] h-[70px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                                {/* saturn Rings */}
                                <div className="absolute w-[75px] h-[75px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                                <img src="/images/planets/saturn/saturn.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита Урана */}
                        <div className="absolute w-[620px] h-[620px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-uran')} className="z-[5] h-[660px] w-[660px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* uran */}
                        <div className="absolute h-[29px] w-[29px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[25]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(310px)` }}
                                className="relative flex items-center justify-center"
                            >

                                {/* uran Rings */}
                                <div className="absolute w-[40px] h-[40px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                                {/* uran Rings */}
                                <div className="absolute w-[50px] h-[50px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                                {/* uran Rings */}
                                <div className="absolute w-[55px] h-[55px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                                {/* uran Rings */}
                                <div className="absolute w-[65px] h-[65px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />


                                <img src="/images/planets/uran/uran.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита Нептуна */}
                        <div className="absolute w-[700px] h-[700px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-neptun')} className="z-[4] h-[730px] w-[730px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* neptun */}
                        <div className="absolute h-[28px] w-[28px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[26]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(350px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/neptun/neptun.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* Орбита Плутона */}
                        <div className="absolute w-[760px] h-[760px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />

                        {/* tolltip */}
                        <div data-tooltip={t('zodiac-pluto')} className="z-[3] h-[780px] w-[780px] cursor-pointer rounded-full rotate-[180deg] absolute title-style title_style_top"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* pluto */}
                        <div className="absolute h-[8px] w-[8px] z-[1]"
                            style={{ 'transform': `rotate(${-moonInfo[27]}deg` }}

                            // style={{ 'transform': `translateX(50px)` }}

                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        >
                            <div
                                style={{ 'transform': `translateX(380px)` }}
                            // className="absolute top-0 left-0"
                            >

                                <img src="/images/planets/pluto/pluto.png" alt='earth' className="pointer-events-none  rounded-full"></img>
                            </div>
                        </div>

                        {/* tolltip */}
                        <div className="z-[2]  h-[850px] w-[850px] cursor-pointer rounded-full rotate-[180deg] absolute"
                            onMouseDown={(e) => handleSwipeStart(e)}
                            onTouchMove={handleThrottledSwipeMove}
                            onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                        />

                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[800px] h-[800px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[810px] h-[810px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[820px] h-[820px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[830px] h-[830px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[840px] h-[840px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />
                        {/* Орбита Астероидов Внешняя */}
                        <div className="absolute w-[850px] h-[850px] outline-dashed outline-slate-700 rounded-full outline-[1px] earth_moon_center_rotate" />


                    </div>

                </div>

                <div className="flex items-center justify-center -rotate-90 z-[10] p-4 cursor-pointer"
                    onMouseDown={(e) => handleSwipeStart(e)}
                    onTouchMove={handleThrottledSwipeMove}
                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                >
                    <div className="flex items-center justify-center flex-col cursor-pointer">
                        <DropDownSVG className='animate-pulse' />
                        <DropDownSVG className='w-4 h-4 animate-pulse' />
                        <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                    </div>
                </div>

            </div >
        </>
    )
}