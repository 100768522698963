import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { updateUserOnlineStatus } from "../store/wsSlice";

export let server_deploying_online_status;

window.location.hostname === 'localhost' ? server_deploying_online_status = 'offline' : server_deploying_online_status = 'online'

export let baseBackendUrl;

server_deploying_online_status === 'online' ? baseBackendUrl = '/api' : baseBackendUrl = `http://${window.location.hostname}:8000`

export const WebSocketFunc = () => {
    // const [numberOfSeconds, setNumberOfSeconds] = useState(0)
    // const [howMuchTimeOnline, setHowMuchTimeOnline] = useState();

    const dispatch = useDispatch();

    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

    let socket_online_status;
    // server_deploying_online_status === 'online' ? socket_online_status = new WebSocket(`wss://${window.location.hostname}/api/ws/online_status`) : socket_online_status = new WebSocket(`ws://${window.location.hostname}:8000/ws/online_status`)

    useEffect(() => {
        if (authorizedUser.username) {
        
            if (document.hidden) {window.location.reload()}

            if (!socket_online_status) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                server_deploying_online_status === 'online' ? socket_online_status = new WebSocket(`wss://${window.location.hostname}/api/ws/online_status?online_status&users_online_status`) : socket_online_status = new WebSocket(`ws://${window.location.hostname}:8000/ws/online_status?online_status&users_online_status`)
            }
        }
    
    }, [document.hidden])

    useEffect(() => {
        // console.log(socket_online_status)

        let howMuchTime = 0

        if (socket_online_status?.readyState === 0) {
            // console.log('WebSocket is closed.');
            
            socket_online_status.onopen = (e) => {
                // console.log("WebSocket connection opened");

                howMuchTime = new Date()
        
                socket_online_status.send(
                JSON.stringify(
                    { 
                    group_name: 'online_status',
                    is_online: true,
                    authorized_user: authorizedUser.username, 
                    last_time_online: '0',
                    how_much_time_online: 0
                    }
                )
                );
                return howMuchTime
            };

            socket_online_status.onmessage = (message_data) => {
                // console.log(socket_online_status)
    
                try {
                const data = JSON.parse(message_data.data);
                // console.log(data)
                dispatch(updateUserOnlineStatus(data))
    
                } catch (e) {
                console.log('Error:', e.message);
                }
            };
            
            socket_online_status.onclose = (e) => {
                console.log('WebSocket соединение закрыто');
    
                socket_online_status?.send(
                    JSON.stringify(
                    { 
                        group_name: 'online_status',
                        is_online: false,
                        authorized_user: authorizedUser.username, 
                        last_time_online: new Date().toISOString(),
                        how_much_time_online: Math.round((new Date() - howMuchTime) / 1000)
                    }
                    )
                );
            };
        } 
        
        

    
        const handleFocus = () => {
            if (socket_online_status?.readyState === 3) {
                window.location.reload()
            }

            howMuchTime = new Date()

            socket_online_status?.send(
            JSON.stringify(
                { 
                group_name: 'online_status',
                is_online: true,
                authorized_user: authorizedUser.username, 
                last_time_online: '0',
                how_much_time_online: 0
                }
            )
            );
            return howMuchTime
        }

        const handleBlur = () => {
            if (socket_online_status?.readyState === 3) {
                window.location.reload()
            }


            socket_online_status?.send(
            JSON.stringify(
                { 
                group_name: 'online_status',
                is_online: false,
                authorized_user: authorizedUser.username, 
                last_time_online: new Date().toISOString(),
                how_much_time_online: Math.round((new Date() - howMuchTime) / 1000)
                }
            )
            );
        }
    
  
  
      // let inactivityTimer = null;
      // const INACTIVITY_TIMEOUT = 60000; // 60 секунд (примерное значение)
    
      // const handleActivity = () => {
      //   clearTimeout(inactivityTimer); // Сбрасываем таймер при активности
      //   inactivityTimer = setTimeout(handleBlur, INACTIVITY_TIMEOUT);
      // };
      
  
      // const checkWindowFocus = () => {
      //   setIsOnline(document.hasFocus());
      //   // console.log(document.hasFocus(), isOnline)
      //   document.hasFocus() ? handleFocus() : handleBlur()
  
      // };
  
      // checkWindowFocus();
  
      window.addEventListener('focus', handleFocus);
      window.addEventListener('blur', handleBlur);
    //   window.addEventListener('online', handleFocus);
    //   window.addEventListener('offline', handleBlur);
      // window.addEventListener('mousemove', handleActivity);
      // window.addEventListener('keydown', handleActivity);
  
      window.addEventListener('beforeunload', handleBlur);
  
    //   window.addEventListener("visibilitychange", () => {
    //     if (document.hidden) {
    //       handleBlur()
    //     } else {
    //       handleFocus()
    //     }
    //   });
  
  
  
      return () => {
        window.removeEventListener('focus', handleFocus);
        window.removeEventListener('blur', handleBlur);
        // window.removeEventListener('online', handleFocus);
        // window.removeEventListener('offline', handleBlur);
        // window.removeEventListener('mousemove', handleActivity);
        // window.removeEventListener('keydown', handleActivity);
  
        window.removeEventListener('beforeunload', handleBlur);
  
        // window.removeEventListener("visibilitychange", () => {
        //   if (document.hidden) {
        //     handleBlur()
        //   } else {
        //     handleFocus()
        //   }
        // });
  
      };
  
      // Очистка при размонтировании компонента
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}