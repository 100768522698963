import { useSelector } from "react-redux"
import { useState } from "react"
import PopupContainer from "../popups_root"
import { t } from "i18next"
// import OrbitYearSVG from "../../../svg/svg_orbit_year"
import { moonPosition } from "../calculations/zodiac_calculations"
import { citiesLatLong } from "../calculations/zodiac_calculations"
import InfoSVG from "../../../svg/svg_info"

export const EveryDayInfluence = () => {
    const profileUser = useSelector(state => state.profileUserSlice.profileUser)
    const user = profileUser?.user.first_name

    const [retrogradeInfoPopupOpen, setRetrogradeInfoPopupOpen] = useState(false);
    const [retrogradePlanet, setRetrogradePlanet] = useState('');


    const matrixList = useSelector(state => state.profileUserSlice.matrixListOfEnergies)
    // const matrixListOfEnergies = matrixList[0]
    const matrixYear = matrixList[5]

    const [yearEnergyPopupOpen, setYearEnergyPopupOpen] = useState(false);

    const moonInfo = moonPosition(new Date(), citiesLatLong['Москва'])

    return (
        <div className="flex flex-col sm:items-start items-center justify-center p-10 gap-10 w-fit">
            <p className="popup_title">{t('profile_today_influence')} {user}:</p>

            <div className="w-[300px] flex">

                <div className="flex gap-3 items-center justify-center" >
                    <p className="">{t('profile-matrix-instructions-year')} - </p>

                    <div className="button_blue !w-10 !h-10 !rounded-full flex items-center justify-center" onClick={() => setYearEnergyPopupOpen(!yearEnergyPopupOpen)}>
                        {/* <OrbitYearSVG className='w-8 h-8 fill-red-300 hover:fill-white' /> */}
                        {matrixYear}
                    </div>
                </div>

                {yearEnergyPopupOpen ? <PopupContainer choosePopup={'year_energy_description'} setPopupOpen={setYearEnergyPopupOpen} additionInfo={null} /> : null}

            </div>


            <div className='w-[300px] flex items-center justify-center flex-col gap-2 text-[10px]'>

                {
                    moonInfo[40]?.mercury[0]
                        ?
                        <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.mercury[0] ? 'text-red-500' : null}`}>
                            <div className="flex items-center gap-2">
                                <p className='text-[16px]'>{t('zodiac-mercury')}</p>
                                <button className="touch_screen_non_selectable" onClick={() => { setRetrogradeInfoPopupOpen(!retrogradeInfoPopupOpen); setRetrogradePlanet("mercury") }}>
                                    <InfoSVG className={'touch_screen_non_selectable button_gray_square !p-0 !w-5 !h-5 !rounded-full hover:fill-blue-500 cursor-pointer'} />
                                </button>
                            </div>
                            {moonInfo[40]?.mercury[0] ? <p>{t('retrograd_now_to')}{moonInfo[40]?.mercury[3]}</p> : null}
                            <p className='text_in_menu'>Следующий ретроград c {moonInfo[40]?.mercury[1]} - {moonInfo[40]?.mercury[2]}</p>
                        </div>
                        : null
                }


                {moonInfo[40]?.venus[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.venus[0] ? 'text-red-500' : null}`}>
                        <div className="flex items-center gap-2">
                            <p className='text-[16px]'>{t('zodiac-venus')}</p>
                            <button className="touch_screen_non_selectable" onClick={() => { setRetrogradeInfoPopupOpen(!retrogradeInfoPopupOpen); setRetrogradePlanet("venus") }}>
                                <InfoSVG className={'touch_screen_non_selectable button_gray_square !p-0 !w-5 !h-5 !rounded-full hover:fill-blue-500 cursor-pointer'} />
                            </button>
                        </div>
                        {moonInfo[40]?.venus[0] ? <p>{t('retrograd_now_to')}{moonInfo[40]?.venus[3]}</p> : null}
                        <p className='text_in_menu'>Следующий ретроград c {moonInfo[40]?.venus[1]} - {moonInfo[40]?.venus[2]}</p>
                    </div>
                    : null}


                {moonInfo[40]?.mars[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.mars[0] ? 'text-red-500' : null}`}>
                        <div className="flex items-center gap-2">
                            <p className='text-[16px]'>{t('zodiac-mars')}</p>
                            <button className="touch_screen_non_selectable" onClick={() => { setRetrogradeInfoPopupOpen(!retrogradeInfoPopupOpen); setRetrogradePlanet("mars") }}>
                                <InfoSVG className={'touch_screen_non_selectable button_gray_square !p-0 !w-5 !h-5 !rounded-full hover:fill-blue-500 cursor-pointer'} />
                            </button>
                        </div>
                        {moonInfo[40]?.mars[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.mars[3]} </p> : null}
                        <p className='text_in_menu'>Следующий ретроград c {moonInfo[40]?.mars[1]} - {moonInfo[40]?.mars[2]}</p>
                    </div>
                    : null}


                {moonInfo[40]?.jupiter[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.jupiter[0] ? 'text-red-500' : null}`}>
                        <div className="flex items-center gap-2">
                            <p className='text-[16px]'>{t('zodiac-jupiter')}</p>

                            <button className="touch_screen_non_selectable" onClick={() => { setRetrogradeInfoPopupOpen(!retrogradeInfoPopupOpen); setRetrogradePlanet("jupiter") }}>
                                <InfoSVG className={'touch_screen_non_selectable button_gray_square !p-0 !w-5 !h-5 !rounded-full hover:fill-blue-500 cursor-pointer'} />
                            </button>
                        </div>
                        {moonInfo[40]?.jupiter[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.jupiter[3]} </p> : null}
                        <p className='text_in_menu'>Следующий ретроград c {moonInfo[40]?.jupiter[1]} - {moonInfo[40]?.jupiter[2]}</p>
                    </div>
                    : null}


                {moonInfo[40]?.saturn[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.saturn[0] ? 'text-red-500' : null}`}>
                        <div className="flex items-center gap-2">
                            <p className='text-[16px]'>{t('zodiac-saturn')}</p>
                            <button className="touch_screen_non_selectable" onClick={() => { setRetrogradeInfoPopupOpen(!retrogradeInfoPopupOpen); setRetrogradePlanet("saturn") }}>
                                <InfoSVG className={'touch_screen_non_selectable button_gray_square !p-0 !w-5 !h-5 !rounded-full hover:fill-blue-500 cursor-pointer'} />
                            </button>
                        </div>
                        {moonInfo[40]?.saturn[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.saturn[3]} </p> : null}
                        <p className='text_in_menu'>Следующий ретроград c {moonInfo[40]?.saturn[1]} - {moonInfo[40]?.saturn[2]}</p>
                    </div>
                    : null}


                {
                    moonInfo[40]?.uran[0]
                        ?
                        <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.uran[0] ? 'text-red-500' : null}`}>
                            <div className="flex items-center gap-2">
                                <p className='text-[16px]'>{t('zodiac-uran')}</p>
                                <button className="touch_screen_non_selectable" onClick={() => { setRetrogradeInfoPopupOpen(!retrogradeInfoPopupOpen); setRetrogradePlanet("uranus") }}>
                                    <InfoSVG className={'touch_screen_non_selectable button_gray_square !p-0 !w-5 !h-5 !rounded-full hover:fill-blue-500 cursor-pointer'} />
                                </button>
                            </div>
                            {moonInfo[40]?.uran[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.uran[3]} </p> : null}
                            <p className='text_in_menu'>Следующий ретроград c {moonInfo[40]?.uran[1]} - {moonInfo[40]?.uran[2]}</p>
                        </div>
                        : null
                }


                {moonInfo[40]?.neptun[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.neptun[0] ? 'text-red-500' : null}`}>
                        <div className="flex items-center gap-2">
                            <p className='text-[16px]'>{t('zodiac-neptun')}</p>
                            <button className="touch_screen_non_selectable" onClick={() => { setRetrogradeInfoPopupOpen(!retrogradeInfoPopupOpen); setRetrogradePlanet("neptun") }}>
                                <InfoSVG className={'touch_screen_non_selectable button_gray_square !p-0 !w-5 !h-5 !rounded-full hover:fill-blue-500 cursor-pointer'} />
                            </button>
                        </div>
                        {moonInfo[40]?.neptun[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.neptun[3]} </p> : null}
                        <p className='text_in_menu'>Следующий ретроград c {moonInfo[40]?.neptun[1]} - {moonInfo[40]?.neptun[2]}</p>
                    </div>
                    : null
                }


                {moonInfo[40]?.pluto[0]
                    ?
                    <div className={`flex flex-col justify-center w-[300px] ${moonInfo[40]?.pluto[0] ? 'text-red-500' : null}`}>
                        <div className="flex items-center gap-2">
                            <p className='text-[16px]'>{t('zodiac-pluto')}</p>
                            <button className="touch_screen_non_selectable" onClick={() => { setRetrogradeInfoPopupOpen(!retrogradeInfoPopupOpen); setRetrogradePlanet("pluto") }}>
                                <InfoSVG className={'touch_screen_non_selectable button_gray_square !p-0 !w-5 !h-5 !rounded-full hover:fill-blue-500 cursor-pointer'} />
                            </button>
                        </div>
                        {moonInfo[40]?.pluto[0] ? <p>{t('retrograd_now_to')} {moonInfo[40]?.pluto[3]} </p> : null}
                        <p className='text_in_menu'>Следующий ретроград c {moonInfo[40]?.pluto[1]} - {moonInfo[40]?.pluto[2]}</p>

                    </div>
                    : null
                }


            </div>

            {retrogradeInfoPopupOpen ? <PopupContainer choosePopup={'retrograde_info'} setPopupOpen={setRetrogradeInfoPopupOpen} additionInfo={[retrogradePlanet]} /> : null}
        </div>
    )
}