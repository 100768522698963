import axios from 'axios'
import React, { useState, useEffect } from "react"
import { baseBackendUrl } from './websocket_comp';
import SavedSVG from "../../svg/saved_svg";
import ProfileSVG from "../../svg/profile_svg";
// import LogoutSVG from "../../svg/logout_svg";
import LoginSVG from "../../svg/login_svg";
import RegistrationSVG from "../../svg/registration_svg";
import OptionsSVG from '../../svg/option_svg';
import Cookies from 'js-cookie';
import FriendsSVG from '../../svg/friends_svg';
import PlanetSVG from '../../svg/planet_svg';
import HomeSVG from '../../svg/home_page_svg';
import UniverseSVG from '../../svg/svg_universe';
import { useNavigate } from 'react-router-dom';
import PostsSVG from '../../svg/svg_posts';
import CommentStrokeSVG from '../../svg/comment_stroke_svg';
import { useDispatch } from "react-redux";
import { updateNonReadMessages } from "../store/wsChatSlice";
import { useSelector } from "react-redux";
import { t } from "i18next";
import PopupContainer from './popups_root';
import PlusSVG from '../../svg/svg_plus';
import { DropDownSVGLeft } from '../../svg/dropDown_svg';
import MatrixSVG from '../../svg/svg_matrix';
import { MoodSmile } from '../../svg/svg_mood_smile';
import { PythagorSquareSVG } from '../../svg/svg_pythagor_square';

const NavItem = ({ className, id, icon: Icon, pageName = null, linkInside = null, nowLink = null, additionLink = null, unreadMessages = null, authorizedUserUsername = null, username = null }) => {
  // console.log(window.location.pathname === (nowLink), nowLink, window.location.pathname)
  return (
    <li id={id} className={`${className} duration-300 touch_screen_non_selectable flex sm:justify-center sm:items-center sm:pt-[14px] p-[0.4rem] text_in_menu cursor-pointer select-none w-full lg:rounded-full ease-in-out  sm:border-t-2 border_color button_navigation sm:scale-100 ${window.location.pathname === (nowLink) ? 'sm:!border-t-0 sm:!border-b-4' : ''} `}
      onClick={linkInside}>

      <div className={`touch_screen_non_selectable 
        ${window.location.pathname === (nowLink) ? '!text-blue-500' : ''} flex gap-2 justify-center hover:text-gray-500 rounded-full sm:rounded-none`} >

        {/* отображение иконки или картинки */}
        {additionLink
          ? <div className="pointer-events-none absoulte bg-cover bg-no-repeat bg-center w-[1.5em] h-[1.5em] rounded-full" style={{ backgroundImage: `url(${baseBackendUrl + additionLink})` }} />
          : <div className='relative flex items-center justify-center'>
            <Icon className="p-[1px] " />
            {
              unreadMessages === 'no' || (unreadMessages === true && authorizedUserUsername === username)
                ? <div className='absolute w-[10px] h-[10px] rounded-full bg-red-500 top-0 -right-1 border border_color_rounded_elements' />
                : null
            }
          </div>}

        <p className="sm:text-[8px] sm:absolute sm:top-0 text-center whitespace-nowrap text-ellipsis">{id ? t(`navigate-${id}`) : null}</p>
        <p className="sm:text-[8px] sm:absolute sm:top-0 text-center">{pageName ? pageName + ' :' : null}</p>

      </div>
    </li>
  )
}


const Navigation = ({ loginList, registerList, setMoodNotificationInner=null }) => {
  const [moodNotification, setMoodNotification] = useState(false)

  const [data, setData] = useState('');
  const [postsTurnedOn, setPostsTurnedOn] = useState(false);

  const [profileMenuOn, setProfileMenuOn] = useState(false);

  const [createPostPopupOpen, setCreatePostPopupOpen] = useState(false);

  // const [username, setUsername] = useState('');
  const headers = { headers: { 'X-CSRFToken': Cookies.get('csrftoken') } };
  const url = `${baseBackendUrl}/check_navigation`;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const chatColorStatusInner = useSelector(state => state.wsChatStatus.chatColorStatus)
  const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)

  const profileUser = useSelector(state => state.profileUserSlice.profileUser);
  // useSelector(state => state.profileUserSlice.profileUser) ? profileUser = useSelector(state => state.profileUserSlice.profileUser) : null

  const user = profileUser?.user || authorizedUser
  const userUsername = user?.username || authorizedUser?.username
  const userFirstName = user?.first_name || authorizedUser.first_name
  const userProfilePic = user?.profile_pic || authorizedUser.profile_pic
  // console.log(user, authorizedUser )

  const fetchData = async () => {

    try {
      const data = { 'username': authorizedUser?.username }
      const response = await axios.post(url, data, headers);

      if (response.data.user === 'not_authorized_user') {
        navigate('/')
      }

      setData(response.data);

      dispatch(updateNonReadMessages(data.non_read_history_messages))


    } catch (error) {
      console.error(error);
    }

  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatColorStatusInner]);

  useEffect(() => {

    const getMoodBaseList = async () => {
      const todayDate = new Date()
      const todayOnlyDate = `${todayDate.getFullYear()}-${(todayDate.getMonth() + 1).toString().padStart(2, '0')}-${todayDate.getDate().toString().padStart(2, '0')}`

      const url = `${baseBackendUrl}/${authorizedUser?.username}/get_mood_base_list`;
      const response = await axios.post(url);
      // console.log(response.data)
      setMoodNotification(response.data.mood_base_list?.at(-1)?.date === todayOnlyDate && response.data.mood_detailed_list?.at(-1)?.date === todayOnlyDate ? false : true)
      // setAllDetaileMoodList(response.data.mood_detailed_list)

    }

    getMoodBaseList()
    // eslint-disable-next-line
  }, [setMoodNotificationInner]);


  const users_count = data.users_count
  // console.log(data.users_count)
  // const user_photo = data.user ? data.user.profile_pic : null;

  const user_photo = data.user ? null : null;

  const nonReadHistoryMessages = data.non_read_history_messages
  const unreadMessagesToMe = nonReadHistoryMessages?.filter(user => user.username === authorizedUser?.username)?.find(user => user.user_message_been_read === false) ? 'no' : 'yes'

  return (
    <div className="flex flex-col gap-2 sm:gap-0 pt-[44px] pb-[44px]">

      {/* LOGO */}
      {window.location.pathname !== '/join'
        ?
        <div className='touch_screen_non_selectable sm:hidden cursor-pointer flex flex-col items-center justify-center hover:animate-pulse ease-in-out duration-500 sm:gap-1'
          onClick={() => { navigate('about') }}
        >
          <img className=" pointer-events-none w-20 h-20 sm:w-15 sm:h-15 hover:animate-pulse" src="/images/logo.png" alt="logo" />
          <p>Destiny Matrix Net</p>
        </div>
        : null
      }


      {/* PANEL */}
      <nav className="sticky top-2 sm:z-[2] h-fit ">
        {/* ${window.matchMedia('(display-mode: standalone)').matches ? */}

        {/* Если приложение а не сайт то выдвинуть навигацию выше */}
        <ul className={`navigation !p-0 !bg-transparent !shadow-none ${window.matchMedia('(display-mode: standalone)').matches ? 'sm:!h-[65px]' : 'sm:!h-[50px]'} sm:items-start`}>

          {authorizedUser?.username
            ? (
              <>
                {/* КНОПКА ПОСТЫ тут */}
                {postsTurnedOn || profileMenuOn
                  ? null
                  : <div className='sm:w-[100px]' onClick={() => { setPostsTurnedOn(!postsTurnedOn) }}><NavItem id="posts" icon={PostsSVG} /></div>
                }

                {
                  postsTurnedOn
                    ? <>

                      {/* Внутренняя панель ПОСТЫ */}
                      <div className={`${window.matchMedia('(display-mode: standalone)').matches ? 'sm:!h-[65px]' : 'sm:!h-[50px]'} items-start  z-[2] flex absolute  sm:fixed sm:bottom-0 sm:flex-row sm:w-full sm:p-0 sm:rounded-none flex-col w-[200px] shadow-md rounded-lg p-3 `}
                      // onClick={() => setPostsTurnedOn(!postsTurnedOn)}
                      >

                        <div onClick={() => setPostsTurnedOn(!postsTurnedOn)} className='sm:w-[300px] w-full flex items-center justify-center'>
                          <NavItem id="back" icon={DropDownSVGLeft} className='sm:h-[45px]' />
                        </div>

                        <NavItem id="following" icon={HomeSVG} linkInside={() => navigate('/following')} nowLink={'/following'} />
                        <NavItem id="all_posts_list" icon={PlanetSVG} linkInside={() => navigate('/')} nowLink={'/'} />
                        <NavItem id="saved" icon={SavedSVG} linkInside={() => navigate('/saved')} nowLink={'/saved'} />

                        {/* ЗЕЛЁНАЯ КНОПКА ЗАПОСТИТЬ */}
                        <NavItem id="profile-createPost" icon={PlusSVG} className={'!bg-green-500 !text-white lg:rounded-full lg:mt-2 lg:mb-1 lg:p-1 flex justify-center'} linkInside={() => setCreatePostPopupOpen(!createPostPopupOpen)} />

                      </div>

                      {/* Задний прозрачный фон слоями */}
                      <div className='popup_messages_bg w-[1000px] h-[200px] absolute lg:hidden'></div>
                      <div className='popup_messages_bg w-[1000px] h-[200px] absolute lg:hidden'></div>
                      <div className='popup_messages_bg w-[1000px] h-[200px] absolute lg:hidden'></div>
                      <div className='popup_messages_bg w-[1000px] h-[200px] absolute lg:hidden'></div>
                      <div className='popup_messages_bg w-[1000px] h-[200px] absolute lg:hidden'></div>

                    </>
                    : null
                }

                {
                  profileMenuOn
                    ?
                    <div className={`${window.matchMedia('(display-mode: standalone)').matches ? 'sm:!h-[65px]' : 'sm:!h-[50px]'} items-start  z-[2] flex absolute  sm:fixed sm:bottom-0 sm:flex-row sm:w-full sm:p-0 sm:rounded-none flex-col w-[200px] shadow-md rounded-lg p-3 `}
                    // onClick={() => { setProfileMenuOn(!profileMenuOn) }}
                    >

                      <div onClick={() => { setProfileMenuOn(!profileMenuOn) }} className='lg:hidden w-[300px]'>
                        <NavItem id="back" icon={DropDownSVGLeft} className='h-[45px]' />
                      </div>

                      <NavItem className='button_in_navigation sm:!w-[410px]' id="profile" icon={ProfileSVG} linkInside={() => navigate(`/${authorizedUser?.username}`)} nowLink={`/${authorizedUser?.username}`} />

                      {userFirstName
                        ? <NavItem pageName={userFirstName} linkInside={() => navigate(`/${userUsername}`)} icon={ProfileSVG} nowLink={`/${userUsername}`} additionLink={userProfilePic} />
                        : <NavItem pageName={authorizedUser.first_name} linkInside={() => navigate(`/${authorizedUser?.username}`)} icon={ProfileSVG} nowLink={`/${authorizedUser?.username}`} additionLink={authorizedUser.profile_pic} />
                      }
                      <NavItem id="matrix" icon={MatrixSVG} linkInside={() => navigate(`/${userUsername}/matrix`)} nowLink={`${userUsername}/matrix`} />
                      <NavItem id="planets" icon={UniverseSVG} linkInside={() => navigate(`/${userUsername}/planets`)} nowLink={`/${userUsername}/planets`} />
                      <NavItem id="mood" icon={MoodSmile} linkInside={() => navigate(`/${userUsername}/mood`)} nowLink={`/${userUsername}/mood`} unreadMessages={moodNotification} authorizedUserUsername={authorizedUser?.username} username={user?.username} />
                      <NavItem id="pifagor" icon={PythagorSquareSVG} linkInside={() => navigate(`/${userUsername}/pifagor`)} nowLink={`/${userUsername}/pifagor`} />
                    </div>
                    : null
                }

                {postsTurnedOn || profileMenuOn
                  ? null
                  :
                  <div className='w-full flex flex-col lg:gap-10'>

                    {/* ОСНОВНОЕ меню */}
                    <div className='w-full sm:flex'>

                      <NavItem className='sm:hidden' id="profile" icon={ProfileSVG} linkInside={() => navigate(`/${authorizedUser?.username}`)} nowLink={`/${authorizedUser?.username}`} additionLink={user_photo} />
                      {/* <NavItem className='lg:hidden' id="small_profile" icon={ProfileSVG} linkInside={() => navigate(`/${authorizedUser?.username}`)} nowLink={`/${authorizedUser?.username}`} /> */}

                      <div className='lg:hidden sm:w-full' onClick={() => { setProfileMenuOn(!profileMenuOn) }}>
                        <NavItem id="small_profile" icon={ProfileSVG} additionLink={null} />
                      </div>

                      <NavItem id="friends" icon={FriendsSVG} linkInside={() => navigate('/friends')} nowLink={'/friends'} />
                      <NavItem id="messages" icon={CommentStrokeSVG} linkInside={() => navigate('/messages')} nowLink={'/messages'} unreadMessages={unreadMessagesToMe} />
                      <NavItem id="options" icon={OptionsSVG} linkInside={() => navigate('/options')} nowLink={'/options'} />
                    </div>

                    {/* Появляется профиль другого человека */}
                    <div className='sm:hidden'>
                      {userUsername
                        ?

                        <div>
                          <NavItem pageName={userFirstName} linkInside={() => navigate(`/${userUsername}`)} icon={ProfileSVG} nowLink={`/${userUsername}`} additionLink={userProfilePic} />
                          {/* <NavItem id="matrix" icon={MatrixSVG} linkInside={() => navigate(`${userUsername}/matrix`)} nowLink={`/${userUsername}/matrix`} /> */}
                          <NavItem id="matrix" icon={MatrixSVG} linkInside={() => navigate(`${userUsername}/matrix`)} nowLink={`/${userUsername}/matrix`} />
                          <NavItem id="planets" icon={UniverseSVG} linkInside={() => navigate(`${userUsername}/planets`)} nowLink={`/${userUsername}/planets`} />
                          <NavItem id="mood" icon={MoodSmile} linkInside={() => navigate(`${userUsername}/mood`)} nowLink={`/${userUsername}/mood`} unreadMessages={moodNotification}  authorizedUserUsername={authorizedUser?.username} username={user?.username} />
                          <NavItem id="pifagor" icon={PythagorSquareSVG} linkInside={() => navigate(`${userUsername}/pifagor`)} nowLink={`/${userUsername}/pifagor`} />

                        </div>
                        : null
                      }
                    </div>


                  </div>

                }

                {createPostPopupOpen ? <PopupContainer choosePopup={'create_post'} setPopupOpen={setCreatePostPopupOpen} additionInfo={['create']} /> : null}

              </>
            )
            :
            (
              <>
                <NavItem id="login" icon={LoginSVG} linkInside={() => loginList[0](!loginList[1])} nowLink={'/login'} />
                <NavItem id="registration" icon={RegistrationSVG} linkInside={() => registerList[0](!registerList[1])} nowLink={'/register'} />
              </>
            )
          }
        </ul>

        {/* Счётчик присоединившихся пользователей */}
        {authorizedUser?.username
          ? ('')
          : (
            <div className="w-full flex flex-col sm:flex-row sm:gap-1 items-center justify-center pt-1 sm:fixed sm:top-0 transparent_bg_for_small sm:p-1">
              <p className="sm:w-fit text-center text-slate-500 w-[150px] text-[14px]" >{t("unauthorized-usersCount")}</p>
              <p id="users_count" className="text-slate-500">{users_count}</p>
            </div>
          )}

      </nav>



    </div>
  );
}

export default Navigation;
