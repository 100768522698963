import { t } from "i18next";
import { MoonRiseSVG, MoonSunsetSVG } from "../../../svg/svg_moon";
import { SunriseSVG, SunsetSVG } from "../../../svg/svg_sunset_sunrise";
import DropDownSVG from "../../../svg/dropDown_svg";
import { formattedDateWithoutClockReversed, formattedDateWithoutClock } from "../services";
import throttle from 'lodash/throttle';
import { updateDisplayNonOverflow } from "../../store/displayNonOverflowSlice";
import { useDispatch } from "react-redux";
import { moonPosition } from "../calculations/zodiac_calculations";
import { useEffect, useState } from "react";
import SunCalc from "suncalc"

export const moon = (moonInfoInner) => {

    return (
        <div
        // style={{ 'rotate': `${moonInfoInner[3] * 90 / Math.PI}deg`}}
        >

            {/* Внешняя тень? */}
            <div className={`w-[300px] h-[300px] rounded-full absolute top-0 lg:m-20 lg:scale-[1.5] sm:scale-[1] outline-dotted opacity-30`} />

            {/* Большой вид */}
            <div style={{ 'transform': `scale(${160 - moonInfoInner[5] * 600}%)` }} className={`sm:hidden relative sm:w-[300px] w-[300px] rounded-full overflow-hidden lg:scale-[1.5] lg:m-20 ease-in-out shadow-[1px_1px_20px_rgba(255,255,255,0.1)]`}>

                <div className="w-full h-full dance_moon">

                    {/* Тень к луне */}
                    {moonInfoInner[1] < 0.5
                        // {false
                        ?
                        <>
                            {moonInfoInner[0] < 50
                                ? <div style={{ 'rotate': `${moonInfoInner[3]}deg`, 'right': `${moonInfoInner[0]}%`, 'borderRadius': `${60 - moonInfoInner[0]}%` }} className={`bg-black w-[130%] h-[130%] -top-10 absolute blur-[5px] opacity-[90%]  bg-clip-content`}></div>
                                : <div style={{ 'rotate': `${moonInfoInner[3]}deg`, 'right': `${0}%`, 'borderRadius': `${moonInfoInner[0] - 40}%`, 'boxShadow': `inset ${300 - moonInfoInner[0] * 3}px 10px 10px 1px black` }} className={`w-[103%] h-[103%] -top-2 absolute blur-[3px] opacity-[90%]  bg-clip-content rounded-full`}></div>
                            }
                        </>

                        :
                        <>
                            {moonInfoInner[0] < 50
                                // {false
                                ? <div style={{ 'rotate': `${moonInfoInner[3]}deg`, 'left': `${moonInfoInner[0]}%`, 'borderRadius': `${60 - moonInfoInner[0]}%` }} className={`bg-black w-[130%] h-[130%] -top-9 absolute blur-[5px] opacity-[90%] bg-clip-content rounded-full`}></div>
                                : <div style={{ 'rotate': `${moonInfoInner[3]}deg`, 'right': `${0}%`, 'borderRadius': `${moonInfoInner[0] - 50}%`, 'boxShadow': `inset ${0 - (100 - moonInfoInner[0]) * 3}px 10px 10px 1px black` }} className={`w-[103%] h-[103%] -top-2 absolute blur-[3px] opacity-[90%]  bg-clip-content rounded-full`}></div>
                            }
                        </>
                    }

                    {/* Фильтр изображения */}
                    <div className="dance_button  backdrop-saturate-[0.3] bg-white/10  w-full h-full absolute rounded-full" />

                    {/* Сама луна */}
                    <img className={`rounded-full`} src="/images/planets/moon/moon.png" alt='moon' />
                </div>

            </div>

            {/* Маленький вид */}
            <div style={{ 'transform': `scale(${110 - moonInfoInner[5] * 600}%)` }} className={`lg:hidden relative sm:w-[300px] w-[300px] rounded-full overflow-hidden lg:scale-[1.5] lg:m-20 ease-in-out shadow-[1px_1px_20px_rgba(255,255,255,0.1)]`}>

                <div className="w-full h-full dance_moon">

                    {/* Тень к луне */}
                    {moonInfoInner[1] < 0.5
                        // {false
                        ?
                        <>
                            {moonInfoInner[0] < 50
                                ? <div style={{ 'rotate': `${moonInfoInner[3]}deg`, 'right': `${moonInfoInner[0]}%`, 'borderRadius': `${60 - moonInfoInner[0]}%` }} className={`bg-black w-[130%] h-[130%] -top-5 absolute blur-[5px] opacity-[90%]  bg-clip-content`}></div>
                                : <div style={{ 'rotate': `${moonInfoInner[3]}deg`, 'right': `${0}%`, 'borderRadius': `${moonInfoInner[0] - 40}%`, 'boxShadow': `inset ${300 - moonInfoInner[0] * 3}px 10px 10px 1px black` }} className={`w-[103%] h-[103%] -top-1 absolute blur-[3px] opacity-[90%]  bg-clip-content rounded-full`}></div>
                            }
                        </>

                        :
                        <>
                            {moonInfoInner[0] < 50
                                // {false
                                ? <div style={{ 'rotate': `${moonInfoInner[3]}deg`, 'left': `${moonInfoInner[0]}%`, 'borderRadius': `${60 - moonInfoInner[0]}%` }} className={`bg-black w-[130%] h-[130%] -top-9 absolute blur-[5px] opacity-[90%] bg-clip-content rounded-full`}></div>
                                : <div style={{ 'rotate': `${moonInfoInner[3]}deg`, 'right': `${0}%`, 'borderRadius': `${moonInfoInner[0] - 40}%`, 'boxShadow': `inset ${0 - (100 - moonInfoInner[0]) * 3}px 10px 10px 1px black` }} className={`w-[103%] h-[103%] -top-1 absolute blur-[3px] opacity-[90%]  bg-clip-content rounded-full`}></div>
                            }
                        </>
                    }

                    {/* Фильтр изображения */}
                    <div className="dance_button  backdrop-saturate-[0.3] bg-white/10  w-full h-full absolute rounded-full" />

                    {/* Сама луна */}
                    <img className={`rounded-full rotate-[${moonInfoInner[3]}]`} src="/images/planets/moon/moon.png" alt='moon' />
                </div>

            </div>
        </div>

    )
}

export const MoonVisual = ({ moonInfoInner, setMoonInfoInner, cityLatLongInner, prevDeltaX, setPrevDeltaX, moonDate, setMoonDate, setTodayDate }) => {
    const [nextNewMoonDay, setNextNewMoonDay] = useState()
    const [nextFullMoonDay, setNextFullMoonDay] = useState()
    const [countLunarDays, setCountLunarDays] = useState()

    const dispatch = useDispatch()

    useEffect(() => {

        if (moonDate) {

            let timeoutReached = false;

            const timeoutId = setTimeout(() => {
                timeoutReached = true;
            }, 2000);  // Set the timeout to 2000 milliseconds (2 seconds)

            let currentDate = new Date(moonDate)

            // Следующее новолуние
            while (true) {
                if (timeoutReached) {
                    break
                }

                let newMoonInfo = SunCalc.getMoonIllumination(currentDate).phase

                if (newMoonInfo >= 0 & newMoonInfo < 0.05) {
                    // return currentDate
                    break
                } else {
                    currentDate.setDate(currentDate.getDate() - 1)
                    // newMoonInfo = moonPosition(currentDate, cityLatLong)
                }

            }


            const thisNewMoon = currentDate
            const todayDay = new Date(moonDate)

            setCountLunarDays(Math.round((todayDay - thisNewMoon) / (1000 * 60 * 60 * 24)) + 1)

            currentDate = new Date(moonDate)

            // Следующее новолуние
            while (true) {
                if (timeoutReached) {
                    break
                }

                let newMoonInfo = SunCalc.getMoonIllumination(currentDate).phase

                if (newMoonInfo >= 0 & newMoonInfo < 0.05) {
                    // return currentDate
                    break
                } else {
                    currentDate.setDate(currentDate.getDate() + 1)
                }

            }

            setNextNewMoonDay(currentDate)

            currentDate = new Date(moonDate)

            // Следующее полнолуние
            while (true) {
                if (timeoutReached) {
                    break
                }

                let newMoonInfo = SunCalc.getMoonIllumination(currentDate).phase

                if (newMoonInfo < 0.55 & newMoonInfo >= 0.5) {
                    // return currentDate
                    break
                } else {
                    currentDate.setDate(currentDate.getDate() + 1)
                }

            }
            setNextFullMoonDay(currentDate)

            clearTimeout(timeoutId);
        }

        // eslint-disable-next-line
    }, [moonInfoInner])

    const handleThrottledSwipeMove = throttle((event) => {
        setTodayDate(false)

        let moveX
        let deltaX
        let finalDeltaX

        dispatch(updateDisplayNonOverflow(true))



        if (event.type === "touchmove") {
            moveX = event.touches[0].clientX;

            finalDeltaX = Math.floor((moveX) / 5);

            if (prevDeltaX > finalDeltaX) {
                deltaX = -1
            } else if (prevDeltaX < finalDeltaX) {
                deltaX = 1
            } else if (prevDeltaX === finalDeltaX) {
                deltaX = 0
            }

            setPrevDeltaX(finalDeltaX)
        }

        let currentDate = new Date(moonDate);
        setMoonDate(currentDate.setDate(currentDate.getDate() + deltaX));
        setMoonInfoInner(moonPosition(currentDate, cityLatLongInner));


    }, 2000);


    const handleSwipeStart = (event) => {

        setTodayDate(false)

        const startX = event.clientX

        const handleSwipeMove = (event) => {
            let moveX
            let deltaX

            if (event.type === "mousemove") {
                moveX = event.clientX;
                deltaX = Math.floor((moveX - startX));

                let currentDate = new Date(moonDate);
                setMoonDate(currentDate.setHours(currentDate.getHours() + deltaX))
                setMoonInfoInner(moonPosition(currentDate, cityLatLongInner))
            }


        };


        const handleSwipeEnd = () => {
            // Очищаем обработчики событий после окончания перетаскивания
            window.removeEventListener('mousemove', handleSwipeMove);
            window.removeEventListener('mouseup', handleSwipeEnd);
        };

        // Добавляем обработчики событий для перемещения мыши и окончания перетаскивания
        window.addEventListener('mousemove', handleSwipeMove);
        window.addEventListener('mouseup', handleSwipeEnd);
    };

    const handleInputDateChange = (e) => {
        const newDate = new Date(e.target.value)
        setMoonDate(newDate)
        setMoonInfoInner(moonPosition(newDate, cityLatLongInner))
    }

    const handleMoonDateToday = () => {
        setTodayDate(true)
        const newDate = new Date()
        setMoonDate(newDate)
        setMoonInfoInner(moonPosition(newDate, cityLatLongInner))
    }





    return (
        <div className="flex flex-col gap-3 items-center justify-center">
            <div className="relative flex flex-col items-center justify-center gap-5">

                <div className="flex items-center justify-center gap-2">


                    <div style={{ 'rotate': `${0}deg` }} className="flex items-center justify-center cursor-pointer"

                    >
                        <div className="flex sm:gap-4 gap-10 items-center justify-center">

                            <div className="flex items-center justify-center rotate-90 ">
                                <div className="flex items-center justify-center flex-col cursor-pointer"
                                    onMouseDown={(e) => handleSwipeStart(e)}
                                    onTouchMove={handleThrottledSwipeMove}
                                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                                >
                                    <DropDownSVG className='animate-pulse' />
                                    <DropDownSVG className='w-4 h-4 animate-pulse' />
                                    <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                                </div>
                            </div>
                            <div
                                onMouseDown={(e) => handleSwipeStart(e)}
                                onTouchMove={handleThrottledSwipeMove}
                                onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                            >
                                {moon(moonInfoInner)}

                            </div>

                            <div className="flex items-center justify-center -rotate-90">
                                <div className="flex items-center justify-center flex-col cursor-pointer"
                                    onMouseDown={(e) => handleSwipeStart(e)}
                                    onTouchMove={handleThrottledSwipeMove}
                                    onTouchEnd={() => dispatch(updateDisplayNonOverflow(false))}
                                >
                                    <DropDownSVG className='animate-pulse' />
                                    <DropDownSVG className='w-4 h-4 animate-pulse' />
                                    <DropDownSVG className=' w-5 h-5 animate-pulse sm:hidden' />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            {/* ИНФО */}
            {/* <p>Процентов освещения луны: {moonInfoInner[0]}</p> */}

            <p className="text-[10px] w-[150px]">{t('profile-moon-distance')}</p>
            <div className="br_border_line_gray" />
            <div className="sticky top-0 flex gap-2 w-full items-center justify-center">
                <input className='w-[200px]' type="date" value={formattedDateWithoutClockReversed(moonDate)} onChange={handleInputDateChange} />
                <button className="button_blue_rounded !w-fit" onClick={handleMoonDateToday}>{t('profile-moon-today')}</button>
            </div>


            <div className="br_border_line_gray" />

            <p>{t('profile-moon-lunarDay')}: {countLunarDays}</p>
            <p>{t('profile-moon-distance-now')}: {Math.floor(moonInfoInner[4] * 1000) / 1000} {t('profile-moon-distance-now-mutch')}</p>
            <p>{moonInfoInner[2]}</p>
            <div className="flex items-center justify-center gap-3">
                {moonInfoInner[7] ? <><MoonRiseSVG className=' bg-slate-300 rounded-full' /> {moonInfoInner[7]} </> : null}
                <MoonSunsetSVG className=' bg-slate-300 rounded-full' /> {moonInfoInner[8]}
            </div>


            <div>
                <p>{t('profile-moon-nextNewMoon')}: {formattedDateWithoutClock(nextNewMoonDay)}</p>
                <p>{t('profile-moon-nextFullMoon')}: {formattedDateWithoutClock(nextFullMoonDay)}</p>
            </div>

            <div className="br_border_line_gray" />

            <p>{t('profile-sun-title')}:</p>

            <div className="flex items-center justify-center gap-3">
                <SunriseSVG className='bg-slate-300 rounded-full fill-black' /> <p>{moonInfoInner[13]} - {moonInfoInner[14]}</p>
                <SunsetSVG className='bg-slate-300 rounded-full fill-black' /><p>{moonInfoInner[15]}</p>
            </div>

            <div className="flex items-center justify-center gap-3">
                <p>{t('profile-sun-zenith')}: {moonInfoInner[16]}</p>
                <p>{t('profile-sun-nadir')}: {moonInfoInner[17]}</p>
            </div>




        </div>

    )
}