import { t } from "i18next"
import { useEffect, useState } from "react";
import PopupContainer from "../popups_root";

export const ProfileRandomCard = () => {
    const [timer, setTimer] = useState(17);
    const [stopInterval, setStopInterval] = useState(true);
    const [destinyNymberPopupOpen, setDestinyNymberPopupOpen] = useState(false);

    useEffect(() => {
        if (stopInterval) {
            const intervalId = setInterval(() => {
                const randomNumber = Math.floor(Math.random() * 22) + 1;
                setTimer(randomNumber);
            }, 50);

            return () => {
                clearInterval(intervalId);
            };
        }

    }, [stopInterval]);

    return (
        <div className="flex flex-col sm:items-center items-center justify-center p-10 gap-10 w-fit">
            <p className="popup_title">{t('profile_question_card_title')} </p>

            <div className="taro_style_upper  w-[200px] sm:w-[150px]" onClick={() => { setDestinyNymberPopupOpen(!destinyNymberPopupOpen) }}>
                <img className="taro_style dance_button pointer-events-none" src={`/images/cards_classic/${timer}.jpg`} alt="classic_cards" />
            </div>

            <button className="button_blue" onClick={() => { setStopInterval(!stopInterval) }}>
                {stopInterval ? t('button_stop') : t('button_start')}
            </button>

            {destinyNymberPopupOpen ? <PopupContainer choosePopup={'matrix_card_description'} setPopupOpen={setDestinyNymberPopupOpen} additionInfo={timer} /> : null}

        </div>
    )
}