import {configureStore, combineReducers} from '@reduxjs/toolkit';
import wsSliceReducer from './wsSlice';
import wsChatReducer from './wsChatSlice';
import authorizedUserReducer from './authorizedUserSlice';
import profileUserReducer from './profileUserInfoSlice';
import displayNonOverflowReducer from './displayNonOverflowSlice';
import postsReducer from './postsSlice';
import optionsReducer from './optionsSlice';

import {  
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER, 
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import historyTimeSliceReducer from './historyTimeSlice';


const rootReducer = combineReducers({

    wsOnlineStatus: wsSliceReducer,
    wsChatStatus: wsChatReducer,
    authorizedUserSlice: authorizedUserReducer,
    profileUserSlice: profileUserReducer,
    displayNonOverflowStatus: displayNonOverflowReducer,
    postsSlice: postsReducer,
    optionsSlice: optionsReducer,
    historyTimeSlice: historyTimeSliceReducer
})

const persistConfig = {
    key: 'root',
    storage, // Здесь используется localStorage
    // blacklist:
    whitelist: ['authorizedUserSlice', 'profileUserSlice'], // Какие части состояния сохранять
  };

const persistedReducer = persistReducer(persistConfig, rootReducer)
  
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })
  
// export default configureStore({
//     reducer: {
//         wsOnlineStatus: wsSliceReducer,
//         wsChatStatus: wsChatReducer
//     },
// });

// const store = createStore(persistReducer);

export const persistor = persistStore(store);
export default store;