import PopupContainer from "../popups_root";
import { t } from "i18next";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { baseBackendUrl } from "../websocket_comp";
import PinkHeartSVG from "../../../svg/pink_heart";
import { useDispatch } from "react-redux";
import { updateProfileAction } from "../../store/profileUserInfoSlice";
import EyeSVG from "../../../svg/eye_svg";
import CommentFillSVG from "../../../svg/comment_fill_svg";
import FollowSVG from "../../../svg/follow_svg";
import FollowingSVG from "../../../svg/following_svg";
import SendMessageSVG from "../../../svg/svg_send_message";
import InfoSVG from "../../../svg/svg_info";
import Following2SVG from "../../../svg/following2_svg";
import Checkbox from "../checkbox";
import ShareSVG from "../../../svg/svg_share";
// import NoiseSVG from "../../../svg/svg_noise";
import TiltWindowComp from "../tilt_window_comp";
import { sumDigitsOfBirthdate } from "../services";

import { ZodiacCalculations } from "../calculations/zodiac_calculations";
import { PifagorCalculationFunc } from "../calculations/profile_pifagor_calculations";
import RoundedGradientAvatar from "../rounded_gradient_avatar";

const ProfileBaseDetails = () => {

    // Доступ к геоданным
    // useEffect(() => {
    //     window.navigator.geolocation.getCurrentPosition(console.log)
    // },[])

    const dispatch = useDispatch();

    const updateProfile = useSelector(state => state.profileUserSlice.updateProfile)

    const profileUser = useSelector(state => state.profileUserSlice.profileUser)
    const authorizedUser = useSelector(state => state.authorizedUserSlice.authorizedUser)
    const onlineUsers = useSelector(state => state.wsOnlineStatus.onlineUsers)

    const cardsUrl = useSelector(state => state.optionsSlice.cardStyleUrl)

    const matrixList = useSelector(state => state.profileUserSlice.matrixListOfEnergies)
    const matrixListOfEnergies = matrixList[0]

    const user = profileUser.user
    const username = user.username
    const firstName = user.first_name
    const lastName = user.last_name
    const profilePic = user.profile_pic
    const birthDate = user.birth_date
    const coverPic = user.cover
    const email = user.email
    const telegram = user.telegram
    const gender = user.gender

    const visits = profileUser.visits

    const zodiacBase = ZodiacCalculations()
    const zodiac = zodiacBase[0]
    const zodiacElement = zodiacBase[1]
    const personChineseZodiac = zodiacBase[2]
    const personChineseElement = zodiacBase[3]

    const destinyNumber = sumDigitsOfBirthdate(birthDate)

    const [count1, count2, count3, count4, count5, count6, count7, count8, count9] = PifagorCalculationFunc()

    // const isOnline = onlineUsers.usersOnline ? onlineUsers.usersOnline.includes(username) : null
    const isOnline = Array.isArray(onlineUsers.usersOnline) ? onlineUsers.usersOnline.includes(username) : null;

    const userLastTimeOnline = onlineUsers.username === username ? onlineUsers.last_time_online : user.last_time_online

    const lastTimeOnlineSec = (new Date() - new Date(userLastTimeOnline)) / 1000
    const lastTimeOnlineMin = lastTimeOnlineSec / 60
    const lastTimeOnlineHours = lastTimeOnlineMin / 60
    const lastTimeOnlineDay = lastTimeOnlineHours / 24
    const lastTimeOnlineWeek = lastTimeOnlineDay / 7
    const lastTimeOnlineMonth = lastTimeOnlineWeek / 4
    const lastTimeOnlineYear = lastTimeOnlineMonth / 12

    let lastTimeOnline = 0



    if (lastTimeOnlineSec < 60) {
        lastTimeOnline = Math.floor(lastTimeOnlineSec) + `${t('lastTimeOnline-sec')}`
    } else {
        if (lastTimeOnlineMin < 60) {
            lastTimeOnline = Math.floor(lastTimeOnlineMin) + `${t('lastTimeOnline-min')}`
        } else {
            if (lastTimeOnlineHours < 24) {
                lastTimeOnline = Math.floor(lastTimeOnlineHours) + `${t('lastTimeOnline-hour')}`
            } else {
                if (lastTimeOnlineDay < 7) {
                    lastTimeOnline = Math.floor(lastTimeOnlineDay) + `${t('lastTimeOnline-day')}`
                } else {
                    if (lastTimeOnlineWeek < 4) {
                        lastTimeOnline = Math.floor(lastTimeOnlineWeek) + `${t('lastTimeOnline-week')}`
                    } else {
                        if (lastTimeOnlineMonth < 12) {
                            lastTimeOnline = Math.floor(lastTimeOnlineMonth) + `${t('lastTimeOnline-month')}`
                        } else {
                            lastTimeOnline = Math.floor(lastTimeOnlineYear) + `${t('lastTimeOnline-year')}`
                        }
                    }
                }
            }
        }
    }

    const followerCount = profileUser.follower_count
    const followers = profileUser.followers
    const isFollower = profileUser.is_follower
    const profileLikes = profileUser.profile_likes

    const editProfileInfo = [username, firstName, lastName, email, birthDate, profilePic, coverPic, telegram]

    // Popups
    const [editProfilePopupOpen, setEditProfilePopupOpen] = useState(false);
    const [addTelegram, setAddTelegram] = useState(false);
    const [messagesPopup, setMessagesPopup] = useState(false);
    const [followerPopupOpen, setFollowerPopupOpen] = useState(false);
    const [profileLikePopupOpen, setProfileLikePopupOpen] = useState(false);
    const [profileInfoPopupOpen, setProfileInfoPopupOpen] = useState(false);
    const [portraitPhoto_1_PopupOpen, setPortraitPhoto_1_PopupOpen] = useState(false);
    const [portraitPhoto_2_PopupOpen, setPortraitPhoto_2_PopupOpen] = useState(false);
    const [portraitPhoto_3_PopupOpen, setPortraitPhoto_3_PopupOpen] = useState(false);
    const [topPictureGradient, setTopPictureGradient] = useState(false);
    const [gradientWallpaperPopupOpen, setGradientWallpaperPopupOpen] = useState(false);
    const [destinyNymberPopupOpen, setDestinyNymberPopupOpen] = useState(false);
    const [zodiacPopupOpen, setZodiacPopupOpen] = useState(false);

    // ПОПАПЫ ПИФАГОРА
    const [pifagorCount1PopupOpen, setPifagorCount1PopupOpen] = useState(false);
    const [pifagorCount2PopupOpen, setPifagorCount2PopupOpen] = useState(false);
    const [pifagorCount3PopupOpen, setPifagorCount3PopupOpen] = useState(false);
    const [pifagorCount4PopupOpen, setPifagorCount4PopupOpen] = useState(false);
    const [pifagorCount5PopupOpen, setPifagorCount5PopupOpen] = useState(false);
    const [pifagorCount6PopupOpen, setPifagorCount6PopupOpen] = useState(false);
    const [pifagorCount7PopupOpen, setPifagorCount7PopupOpen] = useState(false);
    const [pifagorCount8PopupOpen, setPifagorCount8PopupOpen] = useState(false);
    const [pifagorCount9PopupOpen, setPifagorCount9PopupOpen] = useState(false);


    const followUser = async (type, username, authorizedUserUsername) => {
        const url = `${baseBackendUrl}/${username}/${type}`;
        const data = { authorizedUser: authorizedUserUsername };
        await axios.post(url, data);
        dispatch(updateProfileAction(!updateProfile))

    }

    let editSubmitButton = ''

    if (username !== authorizedUser.username) {
        if (isFollower) {
            editSubmitButton = (
                <button className="button_gray_square" id="following-btn" onClick={() => followUser('unfollow', username, authorizedUser.username)}>
                    <p className="sm:hidden">{t('profile-i-following')}</p>
                    <FollowingSVG className={'lg:hidden w-4'} />
                </button>)
        } else {
            editSubmitButton = (
                <button className="button_gray_square" id="follow-btn" onClick={() => followUser('follow', username, authorizedUser.username)}>
                    <p className="sm:hidden">{t('profile-follow')}</p>
                    <FollowSVG className={'lg:hidden w-[14px]'} />
                </button>)
        }
    }

    const creatorNote = () => {
        return (
            <div className="flex justify-end">
                {username === 'propoved'
                    ? <div className="w-fit p-1 pl-3 pr-3 sm:text-sm bg_bright_blue text_inverted_color shadow-lg text-center">{t('profile-creator')} DestinyMatrix.Net</div>
                    : null
                }
            </div>

        )
    }

    const avatar = () => {

        return (
            <div className="-mt-[80px] sm:-mt-[60px]">


                <div className="relative">

                    {/* Зодиак */}
                    {/* <div data-tooltip={t(`profile-zodiac-${zodiac}`)}
                        className="z-[10] title-style title_style_bottom absolute flex items-center justify-center 
                                        sm:top-[100px] sm:-left-3 sm:h-[30px] sm:w-[30px] top-[140px] -left-4 h-[40px] w-[40px] rounded-full">
                        <div className="border-b-[1px] rounded-full p-[1px] border_color">
                            <div className="border-t-[1px] rounded-full p-[1px] border_color">
                                <div className="border-l-[1px] rounded-full border_color">
                                    <img className="sm:w-[30px] w-[50px]" src={`/images/zodiac/zodiac/${zodiac}.png`} alt="zodiac"></img>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* Стихия зодиака */}
                    {/* <div data-tooltip={t(`profile-zodiacElement-${zodiacElement}`)}
                        className="title-style title_style_bottom  absolute flex items-center justify-center 
                                sm:top-[115px] sm:left-[17px] sm:h-[25px] sm:w-[25px] top-[160px] left-6 h-[40px] w-[40px] rounded-full">
                        <div className="border-b-[1px] rounded-full p-[1px] border_color">
                            <div className="border-t-[1px] rounded-full p-[1px] border_color">
                                <div className="border-l-[1px] rounded-full p-[2px] border_color">
                                    <img className="sm:w-[20px] w-[40px] lg:p-1" src={`/images/zodiac/zodiac_elements/${zodiacElement}.png`} alt="zodiacElement"></img>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* Китайский Год Зодиак */}
                    {/* <div data-tooltip={t(`profile-chineseZodiac-${personChineseZodiac}`)}
                        className="title-style title_style_bottom  absolute flex items-center justify-center 
                                    sm:top-[115px] sm:left-[45px] sm:h-[25px] sm:w-[25px] top-[160px] left-16 h-[40px] w-[40px] rounded-full">

                        <div className="border-b-[1px] rounded-full p-[1px] border_color">
                            <div className="border-t-[1px] rounded-full p-[1px] border_color">
                                <div className="border-l-[1px] rounded-full p-[1px] border_color">
                                    <img className="sm:w-[22px] w-[30px] overflow-hidden" src={`/images/zodiac_chinese/years/${personChineseZodiac}.png`} alt="zodiac"></img>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* Китайский Год Зодиак */}
                    {/* <div data-tooltip={t(`profile-chineseZodiac-${personChineseElement}`)}
                        className="title-style title_style_bottom absolute flex items-center justify-center 
                                    sm:top-[100px] sm:left-[74px] sm:h-[25px] sm:w-[25px] top-[140px] left-[105px] h-[40px] w-[40px] rounded-full">

                        <div className="border-b-[1px] rounded-full border_color">
                            <div className="border-t-[1px] rounded-full border_color">
                                <div className="border-l-[1px] rounded-full border_color">
                                    <img className="sm:w-[22px] w-[40px] overflow-hidden rounded-full" src={`/images/zodiac_chinese/elements/${personChineseElement}.png`} alt="zodiac"></img>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    {/* <!-- аватарка ФОТО --> */}
                    {topPictureGradient
                        ?
                        <div className="cursor-pointer" onClick={() => { setGradientWallpaperPopupOpen(!gradientWallpaperPopupOpen) }}>
                            <RoundedGradientAvatar border={4} />
                        </div>

                        :
                        <div className="w-[150px] h-[150px] sm:w-[100px] sm:h-[100px] rounded-full border-4 border_color_rounded_elements bg-cover bg-no-repeat bg-center"
                            style={{ backgroundImage: `url(${baseBackendUrl + profilePic})` }}
                        />
                    }

                    {/* Онлайн значок */}
                    {isOnline
                        ? <div className='absolute left-[14px] top-[118px] sm:top-[78px] sm:left-2 sm:h-[14px] sm:w-[14px] w-5 h-5 rounded-full bg-green-500 border-[3px] border_color_rounded_elements' />
                        : <div className="absolute sm:top-[78px] sm:-left-2 sm:h-[14px] top-[118px] h-5 pl-2 pr-2  rounded-full backdrop-blur-sm backdrop-brightness-[0.6] border-2 border_color_rounded_elements">
                            <p className="flex w-full whitespace-nowrap sm:text-[8px] text-[12px] -m-[1px] text-white">{lastTimeOnline}</p>
                        </div>
                    }

                    {gradientWallpaperPopupOpen ? <PopupContainer choosePopup={'gradient_wallpaper_popup'} setPopupOpen={setGradientWallpaperPopupOpen} additionInfo={''} /> : null}

                </div>
            </div>
        )
    }

    const topPhoto = () => {
        return (
            // {/* <!-- Обложка w-[788px] h-[210px] --> */}
            <div className="w-full aspect-[16/4.5] relative">


                {
                    topPictureGradient
                        ? <div className="w-full h-full top_picture_gradient rounded" />
                        : <div className="w-full h-full bg-cover bg-no-repeat bg-center rounded" style={{ backgroundImage: `url(${baseBackendUrl + coverPic})` }} />
                }

                <div className="absolute top-0 w-full">
                    {creatorNote()}
                </div>

                <div className="absolute bottom-6 -right-2">
                    <Checkbox checkedInner={topPictureGradient} handleCheckboxInner={() => setTopPictureGradient(!topPictureGradient)} />
                </div>

            </div>
        )
    }

    const profileLike = () => {
        return (
            <>
                {/* PROFILE LIKE - ЛАЙК ПРОФИЛЮ */}
                <div className="button_gray_square !w-fit !flex !pt-0 !pb-0 right-0 !pl-1 !pr-1 gap-1 !overflow-visible !h-[20px] !text-[12px] items-center justify-center"
                    onClick={() => setProfileLikePopupOpen(!profileLikePopupOpen)}
                >
                    <div className="pl-2 pr-2 gap-1 flex items-center justify-center">
                        <PinkHeartSVG className='w-[11px] hover:rotate-45 fill-red-500 hover:fill-red-300 ease-in-out duration-300' />
                        <p className="">{profileLikes.length}</p>
                    </div>

                </div>

                {profileLikePopupOpen ? <PopupContainer choosePopup={'profile_like_popup'} setPopupOpen={setProfileLikePopupOpen} additionInfo={[authorizedUser, user, profileLikes]} /> : null}
            </>
        )
    }


    const telegramInProfile = () => {
        return (
            <>
                {/* Telegram */}
                {user.telegram
                    ? authorizedUser.username === username
                        ? null
                        : (
                            <Link className="flex items-center justify-center button_gray_square !w-fit !h-6 !p-0 !pl-2 sm:!pl-3 !pr-2 sm:!pb-1 !text-sm" to={`https://t.me/${user.telegram}`} target="_blank">
                                <SendMessageSVG className={'w-[14px] -rotate-45 lg:hidden'} />
                                <p className="sm:hidden">{t('reg-telegram')}</p>
                                {/* <img
                                        className="w-[21px] duration-300 hover:border-b-0 border-b-4 border rounded-full shadow-lg border_color"
                                        src="images/telegram.png"
                                        alt="telegram"
                                    /> */}
                            </Link>
                        )
                    : (
                        authorizedUser.username === username
                            ? (
                                <button
                                    data-tooltip={t('profile-button_addTelegram')}
                                    className="title-style title_style_addon z-[1] button_blue_rounded !overflow-visible !w-6 !h-6 !p-0"
                                    onClick={() => setAddTelegram(!addTelegram)}
                                >
                                    +
                                </button>
                            )
                            : null
                    )
                }

                {addTelegram ? <PopupContainer choosePopup={'add_telegram_popup'} setPopupOpen={setAddTelegram} additionInfo={[authorizedUser.username]} /> : null}
            </>
        )
    }

    const inviteFriendsButton = () => {
        // const notWorkingFunc = () => {
        //     const props = ['name', 'email', 'tel', 'address', 'icon']
        //     const opts = { multiple: true }
        //     const supported = ('contacts' in navigator && 'ContactsManager' in window)

        //     if (supported) {
        //         const contacts = navigator.contacts.select(props, opts)
        //         console.log(contacts)
        //         alert(contacts)
        //         new Notification(contacts)
        //     }
        // }

        const shareFunc = () => {
            if (navigator.canShare) {
                navigator.share({
                    url: 'https://destinymatrix.net',
                    title: t('profile-watchYourMatrix'),
                    // text: 'yass',
                    icon: {
                        "src": "images/manifest_logos/icon-96x96.png",
                        "sizes": "96x96",
                        "type": "image/png"
                    },
                }).then(() => console.log('Share successful'))
                    .catch((error) => console.log('Error sharing:', error));
            } else {
                // Fallback for browsers that do not support Web Share API
                console.log('Web Share API not supported');
            }
        }

        return (
            <>
                {navigator.canShare
                    ?
                    <button
                        // data-tooltip={t('profile-button_addTelegram')}
                        className="title-style title_style_addon z-[1] button_blue_rounded !overflow-visible !w-6 !h-6 !p-0"
                        onClick={shareFunc}
                    >
                        <div className="flex items-center justify-center">
                            <ShareSVG className="w-[12px] h-[12px] pt-[1px]" />
                        </div>
                    </button>
                    : null
                }
            </>
        )
        // shareFunc()


    }

    const sendMessage = () => {
        return (
            <>
                {/* СООБЩЕНИЕ */}

                {authorizedUser.username !== username
                    ? <div className="button_gray_square !w-fit !h-6 !p-0 !pl-2 !pr-2  !text-sm" onClick={() => setMessagesPopup(!messagesPopup)}>
                        <p className="sm:hidden">{t('profile-messages-sendMessage')}</p>
                        <CommentFillSVG className={'w-4 lg:hidden'} />
                    </div>
                    : null
                }

                {messagesPopup ? <PopupContainer choosePopup={'messages_popup'} setPopupOpen={setMessagesPopup} additionInfo={[username, authorizedUser, firstName, lastName, profilePic, isOnline]} /> : null}

            </>
        )
    }


    const editProfileButton = () => {
        return (
            <>
                {authorizedUser.username === username
                    ? <button className="button_gray_square !text-[11px]" id="edit-profile-btn" onClick={() => setEditProfilePopupOpen(!editProfilePopupOpen)}>{t('profile-editProfile')}</button>
                    : null}

                {/* <!-- Попап редактирования профиля --> */}
                {editProfilePopupOpen ? <PopupContainer choosePopup={'edit_profile'} setPopupOpen={setEditProfilePopupOpen} additionInfo={[editProfileInfo]} /> : null}
            </>
        )
    }

    const profileVisits = () => {
        // {/* Посещения профиля */}
        return (
            <div className="relative">
                <div data-tooltip={t('profile_visits')} className="cursor-pointer z-10 title-style title_style_left flex items-center justify-center gap-1 text-[12px] rounded_text h-5 rounded-full  border-2 border_color_rounded_elements">
                    <EyeSVG className="w-[22px] fill-gray-400" />
                    <p>{visits.visit_count}</p>(<p>{visits.user_whom_visit}</p>)
                </div>
            </div>

        )
    }

    const namesFirstLast = () => {
        return (
            <>
                <p className="flex font-bold text-xl sm:text-sm p-1 gap-1 overflow-x-scroll">{firstName} {lastName}</p>
            </>
        )
    }

    const followersInfo = () => {
        return (
            <>

                {/* Подписчики */}
                <div className="button_gray_square !w-fit !flex !pt-0 !pb-0 right-0 !pl-2 !pr-2 gap-1 !overflow-visible !h-[20px] !text-[12px] items-center justify-centerr"
                    onClick={() => followerCount ? setFollowerPopupOpen(!followerPopupOpen) : ''}>

                    {/* <p>{t('profile-followers')}:</p> */}
                    <Following2SVG className={'w-[14px]'} />
                    <strong id="follower__count" className="">{followerCount || 0}</strong>
                </div>

                {followerPopupOpen ? <PopupContainer choosePopup={'follower'} setPopupOpen={setFollowerPopupOpen} additionInfo={followers} /> : null}

            </>
        )
    }

    const profileInfo = () => {
        return (
            <>
                <button className="touch_screen_non_selectable" onClick={() => { setProfileInfoPopupOpen(!profileInfoPopupOpen) }}>
                    <InfoSVG className={'touch_screen_non_selectable button_gray_square !p-0 !w-6 !h-6 !rounded-full hover:fill-blue-500 cursor-pointer'} />
                </button>

                {profileInfoPopupOpen ? <PopupContainer choosePopup={'profile_info'} setPopupOpen={setProfileInfoPopupOpen} additionInfo={''} /> : null}

            </>
        )
    }



    const matrixPortrait = () => {
        const firstCard = () => {
            return (
                <div className="relative taro_style_upper w-[150px] sm:w-[100px] cursor-pointer">
                    <img className="taro_style dance_button" src={`${cardsUrl}/${matrixListOfEnergies[0]}.jpg`} alt="cards" />

                </div>
            )
        }

        const secondCard = () => {
            return (
                <div className="relative taro_style_upper w-[150px] sm:w-[100px] cursor-pointer">
                    <img className="taro_style dance_button" src={`${cardsUrl}/${matrixListOfEnergies[1]}.jpg`} alt="cards" />
                    {/* <p className="bg-[#F3F4DD] w-full text-[10px] font-bold leading-4 text-center text-black">{t(`taroCards-description-${matrixListOfEnergies[1]}`)}</p> */}

                </div>
            )
        }

        const thirdCard = () => {
            return (
                <div className="relative taro_style_upper w-[150px] sm:w-[100px] cursor-pointer">
                    <img className="taro_style dance_button" src={`${cardsUrl}/${matrixListOfEnergies[4]}.jpg`} alt="cards" />
                    {/* <p className="bg-[#F3F4DD] w-full text-[10px] font-bold leading-4 text-center text-black">{t(`taroCards-description-${matrixListOfEnergies[4]}`)}</p> */}
                </div>
            )
        }

        return (
            <>
                {matrixListOfEnergies ?
                    <div className="flex flex-col items-center justify-center">

                        <p className="z-[1] popup_messages_bg text-sm sm:text-[10px] -mb-2 pl-2 pr-2 rounded-full border border_color">{t('profile-portrait-cards')}</p>

                        <div className="flex gap-2 items-center justify-center border border_color w-fit p-3">
                            {<TiltWindowComp tiltContent={firstCard()} tiltTitle={<p className="bg-[#F3F4DD] w-full text-[10px] font-bold leading-4 text-center text-black">{t(`taroCards-description-${matrixListOfEnergies[0]}`)}</p>} click={(() => setPortraitPhoto_1_PopupOpen(!portraitPhoto_1_PopupOpen))} classNameInner={''} />}
                            {<TiltWindowComp tiltContent={secondCard()} tiltTitle={<p className="bg-[#F3F4DD] w-full text-[10px] font-bold leading-4 text-center text-black">{t(`taroCards-description-${matrixListOfEnergies[1]}`)}</p>} click={() => setPortraitPhoto_2_PopupOpen(!portraitPhoto_2_PopupOpen)} classNameInner={''} />}
                            {<TiltWindowComp tiltContent={thirdCard()} tiltTitle={<p className="bg-[#F3F4DD] w-full text-[10px] font-bold leading-4 text-center text-black">{t(`taroCards-description-${matrixListOfEnergies[4]}`)}</p>} click={() => setPortraitPhoto_3_PopupOpen(!portraitPhoto_3_PopupOpen)} classNameInner={''} />}

                            {portraitPhoto_1_PopupOpen ? <PopupContainer choosePopup={'energys_description'} setPopupOpen={setPortraitPhoto_1_PopupOpen} additionInfo={['zone_birthDay_resource', matrixListOfEnergies[0], gender]} /> : null}
                            {portraitPhoto_2_PopupOpen ? <PopupContainer choosePopup={'energys_description'} setPopupOpen={setPortraitPhoto_2_PopupOpen} additionInfo={['zone_month_bigTalant', matrixListOfEnergies[1], gender]} /> : null}
                            {portraitPhoto_3_PopupOpen ? <PopupContainer choosePopup={'energys_description'} setPopupOpen={setPortraitPhoto_3_PopupOpen} additionInfo={['zone_year_soulComfort', matrixListOfEnergies[4], gender]} /> : null}

                        </div>
                    </div>

                    : null
                }
            </>

        )
    }

    // eslint-disable-next-line
    const experiments = () => {
        return (
            <div className="relative">
                <div className="relative overflow-hidden pl-14">
                    <div className="w-[50px] h-[150px] loader rotate-[86deg]">
                        <div className="w-full h-full rounded-full benzin">
                            <div className="w-full h-full rounded-full benzin">
                                <div className="w-full h-full rounded-full benzin">
                                    <div className="w-full h-full rounded-full benzin">
                                        <div className="w-full h-full rounded-full benzin">
                                            <div className="w-full h-full rounded-full benzin">
                                                <div className="w-full h-full rounded-full benzin">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div className="w-[150px] h-[50px] loader -rotate-[5deg]">
                        <div className="w-full h-full rounded-full benzin_3">
                            <div className="w-full h-full rounded-full benzin_3">
                                <div className="w-full h-full rounded-full benzin_3">
                                    <div className="w-full h-full rounded-full benzin_3">
                                        <div className="w-full h-full rounded-full benzin">
                                            <div className="w-full h-full rounded-full benzin">
                                                <div className="w-full h-full rounded-full benzin">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div className="w-[150px] h-[150px] loader rotate-90 overflow-hidden">
                        <div className="w-full h-full rounded-full benzin">
                            <div className="w-full h-full rounded-full benzin">
                                <div className="w-full h-full rounded-full benzin">
                                    <div className="w-full h-full rounded-full benzin">
                                        <div className="w-full h-full rounded-full benzin">
                                            <div className="w-full h-full rounded-full benzin">
                                                <div className="w-full h-full rounded-full benzin">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div className="w-[150px] h-[150px] loader rotate-90 overflow-hidden">
                        <div className="w-full h-full rounded-full benzin_3">
                            <div className="w-full h-full rounded-full benzin_3">
                                <div className="w-full h-full rounded-full benzin_3">
                                    <div className="w-full h-full rounded-full benzin_3">
                                        <div className="w-full h-full rounded-full benzin">
                                            <div className="w-full h-full rounded-full benzin">
                                                <div className="w-full h-full rounded-full benzin">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div className="w-[150px] h-[150px] loader_2 rotate-90 overflow-hidden">
                        <div className="w-full h-full rounded-full benzin_2">
                            <div className="w-full h-full rounded-full benzin_2">
                                <div className="w-full h-full rounded-full benzin_2">
                                    <div className="w-full h-full rounded-full benzin_2">
                                        <div className="w-full h-full rounded-full benzin_2">
                                            <div className="w-full h-full rounded-full benzin_2">
                                                <div className="w-full h-full rounded-full benzin_2">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <NoiseSVG /> */}
                <button className="absolute top-96 left-20 opacity_transperent_button w-[150px] h-[50px] rounded-full p-5">
                </button>

            </div>
        )
    }

    const miniPifagorView = () => {

        return (
            <div className="p-3 flex items-center justify-center">
                <div className="relative w-fit flex items-center justify-center">

                    <p className="z-[1] absolute popup_messages_bg -top-4 text-[13px] sm:text-[10px] -mb-2 pl-2 pr-2 rounded-full border border_color w-fit">{t('profile-portrait-pifagor')}</p>

                    <div className="flex gap-2 items-center justify-center border border_color w-fit p-3 rounded-xl">

                        <div className="flex flex-col gap-2 sm:gap-1">

                            {/* <div
                                data-tooltip={t('pifagor-character')}
                                className={`relative title-style title_style_bottom pifagor !w-[80px] !h-[20px] !p-0 justify-center text-center ${count1 === '-' ? 'gradient-bg-green' : count1 === '1111111' ? 'gradient-bg-red' : 'gradient-bg'
                                    }`}
                                onClick={() => setPifagorCount1PopupOpen(!pifagorCount1PopupOpen)}
                            >
                                <h1 className="text-[15px]">{count1}</h1>
                            </div> */}
                            {/* {console.log(count1.length)} */}
                            <div className="flex flex-col items-center justify-center w-[100px] text-[10px]">
                                <p className="z-[1] mix-blend-difference">{t('pifagor-1-description_mini')}:</p>

                                <div data-tooltip={t('pifagor-character')} className="relative title-style title_style_bottom pifagor !w-[80px] !h-[20px] !p-0 justify-center text-center" onClick={() => setPifagorCount1PopupOpen(!pifagorCount1PopupOpen)}>
                                <p className="h-4  z-[1] mix-blend-difference">{Math.round(count1 === '-' ? 0 : count1.length / 7 * 100)}%</p>

                                    {/* Нижний слой, задний фон */}
                                    <div className="w-full h-full  pifagor_gradient-bg absolute"></div>
                                    {/* Верхний слой, заполняющая полоска */}
                                    <div className="border-[1px] border_color h-full  popup_messages_bg absolute" style={{ right: 0, width: `${count1 === '-' ? 100 : 100 - count1.length / 7 * 100}%`, mixBlendMode: 'normal' }}></div>

                                </div>
                            </div>

                            {pifagorCount1PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount1PopupOpen} additionInfo={[t(`pifagor-character-${count1}`), count1, 1]} /> : null}

                            <div className="flex flex-col items-center justify-center text-[10px]">
                                <p className="h-4 z-[1] mix-blend-difference">{t('pifagor-2-description_mini')}:</p>

                                <div data-tooltip={t('pifagor-energy')} className="relative title-style title_style_bottom pifagor !w-[80px] !h-[20px] !p-0 justify-center text-center" onClick={() => setPifagorCount2PopupOpen(!pifagorCount2PopupOpen)}>
                                <p className=" z-[1] mix-blend-difference">{Math.round(count2 === '-' ? 0 : count2.length / 7 * 100)}%</p>

                                    {/* Нижний слой, задний фон */}
                                    <div className="w-full h-full  pifagor_gradient-bg absolute"></div>
                                    {/* Верхний слой, заполняющая полоска */}
                                    <div className="border-[1px] border_color h-full  popup_messages_bg absolute" style={{ right: 0, width: `${count2 === '-' ? 100 : 100 - count2.length / 7 * 100}%`, mixBlendMode: 'normal' }}></div>

                                </div>
                            </div>
                            {pifagorCount2PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount2PopupOpen} additionInfo={[t(`pifagor-energy-${count2}`), count2, 2]} /> : null}


                            <div className="flex flex-col items-center justify-center text-[10px]">
                                <p className="h-4  z-[1] mix-blend-difference">{t('pifagor-3-description_mini')}:</p>

                                <div data-tooltip={t('pifagor-interest')} className="relative title-style title_style_bottom pifagor !w-[80px] !h-[20px] !p-0 justify-center text-center" onClick={() => setPifagorCount3PopupOpen(!pifagorCount3PopupOpen)}>
                                <p className=" z-[1] mix-blend-difference">{Math.round(count3 === '-' ? 0 : count3.length / 7 * 100)}%</p>

                                    {/* Нижний слой, задний фон */}
                                    <div className="w-full h-full  pifagor_gradient-bg absolute"></div>
                                    {/* Верхний слой, заполняющая полоска */}
                                    <div className="border-[1px] border_color h-full  popup_messages_bg absolute" style={{ right: 0, width: `${count3 === '-' ? 100 : 100 - count3.length / 7 * 100}%`, mixBlendMode: 'normal' }}></div>

                                </div>
                            </div>
                            {pifagorCount3PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount3PopupOpen} additionInfo={[t(`pifagor-interest-${count3}`), count3, 3]} /> : null}


                        </div>

                        <div className="flex flex-col gap-2 sm:gap-1">

                        <div className="flex flex-col items-center justify-center text-[9px]">
                                <p className="h-4  z-[1] mix-blend-difference">{t('pifagor-4-description_mini')}:</p>

                                <div data-tooltip={t('pifagor-health')} className="relative title-style title_style_bottom pifagor !w-[80px] !h-[20px] !p-0 justify-center text-center" onClick={() => setPifagorCount4PopupOpen(!pifagorCount4PopupOpen)}>
                                <p className=" z-[1] mix-blend-difference">{Math.round(count4 === '-' ? 0 : count4.length / 7 * 100)}%</p>

                                    {/* Нижний слой, задний фон */}
                                    <div className="w-full h-full  pifagor_gradient-bg absolute"></div>
                                    {/* Верхний слой, заполняющая полоска */}
                                    <div className="border-[1px] border_color h-full  popup_messages_bg absolute" style={{ right: 0, width: `${count4 === '-' ? 100 : 100 - count4.length / 7 * 100}%`, mixBlendMode: 'normal' }}></div>

                                </div>
                            </div>
                            {pifagorCount4PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount4PopupOpen} additionInfo={[t(`pifagor-health-${count4}`), count4, 4]} /> : null}


                            <div className="flex flex-col items-center justify-center">
                                <p className="h-4 text-[10px] z-[1] mix-blend-difference">{t('pifagor-5-description_mini')}:</p>

                                <div data-tooltip={t('pifagor-logic')} className="relative title-style title_style_bottom pifagor !w-[80px] !h-[20px] !p-0 justify-center text-center" onClick={() => setPifagorCount5PopupOpen(!pifagorCount5PopupOpen)}>
                                <p className="text-[10px] z-[1] mix-blend-difference">{Math.round(count5 === '-' ? 0 : count5.length / 7 * 100)}%</p>

                                    {/* Нижний слой, задний фон */}
                                    <div className="w-full h-full  pifagor_gradient-bg absolute"></div>
                                    {/* Верхний слой, заполняющая полоска */}
                                    <div className="border-[1px] border_color h-full  popup_messages_bg absolute" style={{ right: 0, width: `${count5 === '-' ? 100 : 100 - count5.length / 7 * 100}%`, mixBlendMode: 'normal' }}></div>

                                </div>
                            </div>
                            {pifagorCount5PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount5PopupOpen} additionInfo={[t(`pifagor-logic-${count5}`), count5, 5]} /> : null}


                            <div className="flex flex-col items-center justify-center  text-[10px]">
                                <p className="h-4 z-[1] mix-blend-difference">{t('pifagor-6-description_mini')}:</p>

                                <div data-tooltip={t('pifagor-work')} className="relative title-style title_style_bottom pifagor !w-[80px] !h-[20px] !p-0 justify-center text-center" onClick={() => setPifagorCount6PopupOpen(!pifagorCount6PopupOpen)}>
                                <p className="z-[1] mix-blend-difference">{Math.round(count6 === '-' ? 0 : count6.length / 7 * 100)}%</p>

                                    {/* Нижний слой, задний фон */}
                                    <div className="w-full h-full  pifagor_gradient-bg absolute"></div>
                                    {/* Верхний слой, заполняющая полоска */}
                                    <div className="border-[1px] border_color h-full  popup_messages_bg absolute" style={{ right: 0, width: `${count6 === '-' ? 100 : 100 - count6.length / 7 * 100}%`, mixBlendMode: 'normal' }}></div>

                                </div>
                            </div>
                            {pifagorCount6PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount6PopupOpen} additionInfo={[t(`pifagor-work-${count6}`), count6, 6]} /> : null}


                            {/* <div className="pifagor pifagor_color_add " onClick={() => setPifagorColumn2PopupOpen(!pifagorColumn2PopupOpen)}>
                                <h1>{count456}</h1>
                                <p>{t('pifagor-money')}</p>

                            </div>
                            {pifagorColumn2PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorColumn2PopupOpen} additionInfo={[t(`pifagor-column-2`), count456, 'column']} /> : null} */}


                        </div>

                        <div className="flex flex-col gap-2 sm:gap-1">

                            <div className="flex flex-col items-center justify-center  text-[10px]">
                                <p className="h-4 z-[1] mix-blend-difference">{t('pifagor-7-description_mini')}:</p>

                                <div data-tooltip={t('pifagor-luck')} className="relative title-style title_style_bottom pifagor !w-[80px] !h-[20px] !p-0 justify-center text-center" onClick={() => setPifagorCount7PopupOpen(!pifagorCount7PopupOpen)}>
                                <p className=" z-[1] mix-blend-difference">{Math.round(count7 === '-' ? 0 : count7.length / 7 * 100)}%</p>

                                    {/* Нижний слой, задний фон */}
                                    <div className="w-full h-full  pifagor_gradient-bg absolute"></div>
                                    {/* Верхний слой, заполняющая полоска */}
                                    <div className="border-[1px] border_color h-full  popup_messages_bg absolute" style={{ right: 0, width: `${count7 === '-' ? 100 : 100 - count7.length / 7 * 100}%`, mixBlendMode: 'normal' }}></div>

                                </div>
                            </div>
                            {pifagorCount7PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount7PopupOpen} additionInfo={[t(`pifagor-luck-${count7}`), count7, 7]} /> : null}


                            <div className="flex flex-col items-center justify-center">
                                <p className="h-4 text-[10px] z-[1] mix-blend-difference">{t('pifagor-8-description_mini')}:</p>

                                <div data-tooltip={t('pifagor-call_of_duty')} className="relative title-style title_style_bottom pifagor !w-[80px] !h-[20px] !p-0 justify-center text-center" onClick={() => setPifagorCount8PopupOpen(!pifagorCount8PopupOpen)}>
                                <p className="text-[10px] z-[1] mix-blend-difference">{Math.round(count8 === '-' ? 0 : count8.length / 7 * 100)}%</p>

                                    {/* Нижний слой, задний фон */}
                                    <div className="w-full h-full  pifagor_gradient-bg absolute"></div>
                                    {/* Верхний слой, заполняющая полоска */}
                                    <div className="border-[1px] border_color h-full  popup_messages_bg absolute" style={{ right: 0, width: `${count8 === '-' ? 100 : 100 - count8.length / 7 * 100}%`, mixBlendMode: 'normal' }}></div>

                                </div>
                            </div>
                            {pifagorCount8PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount8PopupOpen} additionInfo={[t(`pifagor-call_of_duty-${count8}`), count8, 8]} /> : null}

                            <div className="flex flex-col items-center justify-center">
                                <p className="h-4 text-[10px] z-[1] mix-blend-difference">{t('pifagor-9-description_mini')}:</p>

                                <div data-tooltip={t('pifagor-mind')} className="relative title-style title_style_bottom pifagor !w-[80px] !h-[20px] !p-0 justify-center text-center" onClick={() => setPifagorCount9PopupOpen(!pifagorCount9PopupOpen)}>
                                <p className="text-[10px] z-[1] mix-blend-difference">{Math.round(count9 === '-' ? 0 : count9.length / 7 * 100)}%</p>

                                    {/* Нижний слой, задний фон */}
                                    <div className="w-full h-full  pifagor_gradient-bg absolute"></div>
                                    {/* Верхний слой, заполняющая полоска */}
                                    <div className="border-[1px] border_color h-full  popup_messages_bg absolute" style={{ right: 0, width: `${count9 === '-' ? 100 : 100 - count9.length / 7 * 100}%`, mixBlendMode: 'normal' }}></div>

                                </div>
                            </div>
                            {pifagorCount9PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorCount9PopupOpen} additionInfo={[t(`pifagor-mind-${count9}`), count9, 9]} /> : null}


                            {/* <div className="pifagor pifagor_color_add " onClick={() => setPifagorColumn3PopupOpen(!pifagorColumn3PopupOpen)}>
                                <h1>{count789}</h1>
                                <p>{t('pifagor-talant')}</p>

                            </div>
                            {pifagorColumn3PopupOpen ? <PopupContainer choosePopup={'pifagor_popup'} setPopupOpen={setPifagorColumn3PopupOpen} additionInfo={[t(`pifagor-column-3`), count789, 'column']} /> : null} */}


                        </div>

                    </div>
                </div>
            </div>
        )
    }

    const zodiacDescription = () => {
        const zodiacDescription = t(`zodiac_small_description_${zodiac}`).split(',')

        return (
            <>
                <div className="w-full flex items-center justify-center text-xs pb-10 gap-2">
                    <p className="w-[130px]">{t(`profile-zodiac-clue`)}:</p>

                    <div className="flex items-center justify-center w-[50px] relative">

                        <div data-tooltip={t(`profile-zodiac-${zodiac}`)}
                            className="cursor-pointer w-full h-full z-[11] absolute title-style title_style_bottom"
                            onClick={() => { setZodiacPopupOpen(!zodiacPopupOpen) }}
                        />

                        {/* Зодиак */}
                        <div className="z-[1] flex items-center justify-center h-[40px] w-[40px] rounded-full">
                            <div className="border-b-[1px] rounded-full p-[1px] border_color">
                                <div className="border-t-[1px] rounded-full p-[1px] border_color">
                                    <div className="border-l-[1px] rounded-full border_color">
                                        <img className="sm:w-[30px] w-[50px]" src={`/images/zodiac/zodiac/${zodiac}.png`} alt="zodiac"></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="flex flex-col w-[150px]">
                        {zodiacDescription.map((word, index) => (
                            <p key={index}>{word}</p>
                        ))}
                    </div>
                </div>
                {zodiacPopupOpen ? <PopupContainer choosePopup={'zodiac_description'} setPopupOpen={setZodiacPopupOpen} additionInfo={zodiac} /> : null}

            </>
        )
    }

    const zodiacElementDescription = () => {
        const zodiacElementDescription = t(`zodiac_element_small_description_${zodiacElement}`).split(',')

        return (
            <>
                <div className="w-full flex items-center justify-center text-xs pb-10 gap-2">

                    <p className="w-[130px]">{t(`profile-zodiacElement-clue`)}:</p>

                    <div className="flex items-center justify-center w-[50px] relative">

                        <div data-tooltip={t(`profile-zodiacElement-${zodiacElement}`)}
                            className="w-full h-full z-[1] absolute title-style title_style_bottom" />

                        {/* Стихия зодиака */}
                        <div
                            className="flex items-center justify-center h-[40px] w-[40px] rounded-full">
                            <div className="border-b-[1px] rounded-full p-[1px] border_color">
                                <div className="border-t-[1px] rounded-full p-[1px] border_color">
                                    <div className="border-l-[1px] rounded-full p-[2px] border_color">
                                        <img className="sm:w-[20px] w-[40px] lg:p-1" src={`/images/zodiac/zodiac_elements/${zodiacElement}.png`} alt="zodiacElement"></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="flex border-l-[1px] w-fit h-[90px]" /> */}
                    </div>

                    <div className="flex flex-col w-[150px]">
                        {zodiacElementDescription.map((word, index) => (
                            <p key={index}>{word}</p>
                        ))}
                    </div>
                </div>

            </>
        )
    }

    const chineseZodiacDescription = () => {
        const chineseZodiacDescription = t(`profile-chineseZodiac_small_description-${personChineseZodiac}`).split(',')

        return (
            <>
                <div className="w-full flex items-center justify-center text-xs pb-10 gap-2">

                    <p className="w-[130px]">{t(`profile-chinese_zodiac-clue`)}:</p>

                    <div className="flex items-center justify-center w-[50px] relative">

                        <div data-tooltip={t(`profile-chineseZodiac-${personChineseZodiac}`)}
                            className="w-full h-full z-[1] absolute title-style title_style_bottom" />

                        {/* Китайский Год Зодиак */}
                        <div className="title-style title_style_bottom flex items-center justify-center h-[40px] w-[40px] rounded-full">

                            <div className="border-b-[1px] rounded-full p-[1px] border_color">
                                <div className="border-t-[1px] rounded-full p-[1px] border_color">
                                    <div className="border-l-[1px] rounded-full p-[1px] border_color">
                                        <img className="sm:w-[22px] w-[30px] overflow-hidden" src={`/images/zodiac_chinese/years/${personChineseZodiac}.png`} alt="zodiac"></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="flex border-l-[1px] w-fit h-[90px]" /> */}
                    </div>

                    <div className="flex flex-col w-[150px]">
                        {chineseZodiacDescription.map((word, index) => (
                            <p key={index}>{word}</p>
                        ))}
                    </div>
                </div>

            </>
        )
    }

    const chineseZodiacElementDescription = () => {
        const chineseZodiacElementDescription = t(`profile-chineseZodiacElement_small_description-${personChineseElement}`).split(',')

        return (
            <>
                <div className="w-full flex items-center justify-center text-xs gap-2">

                    <p className="w-[130px]">{t(`profile-chinese_zodiac-element-clue`)}:</p>

                    <div className="flex items-center justify-center w-[50px] relative">

                        <div data-tooltip={t(`profile-chineseZodiac-${personChineseElement}`)}
                            className="w-full h-full z-[1] absolute title-style title_style_bottom" />


                        {/* Китайский Год Зодиак */}
                        <div className="flex items-center justify-center h-[40px] w-[40px] rounded-full">

                            <div className="border-b-[1px] rounded-full border_color">
                                <div className="border-t-[1px] rounded-full border_color">
                                    <div className="border-l-[1px] rounded-full border_color">
                                        <img className="sm:w-[22px] w-[40px] overflow-hidden rounded-full" src={`/images/zodiac_chinese/elements/${personChineseElement}.png`} alt="zodiac"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="flex border-l-[1px] w-fit h-[90px]" /> */}
                    </div>

                    <div className="flex flex-col w-[150px]">
                        {chineseZodiacElementDescription.map((word, index) => (
                            <p key={index}>{word}</p>
                        ))}
                    </div>
                </div>

            </>
        )
    }

    const destinyNumberFunc = () => {
        const destinyNumberDescription = t(`destinyNumber_small_description_${destinyNumber}`).split(',')

        return (
            <>
                <div className="w-full flex items-center justify-center text-xs p-5 gap-2">
                    <p className="w-[130px]">{t('destiny_number_title')}:</p>

                    <div className="flex items-center justify-center p-3 gap-2 w-[50px]">
                        <div className="relative w-8 h-8 cursor-pointer hover:bg-blue-300 rounded-full" onClick={() => { setDestinyNymberPopupOpen(!destinyNymberPopupOpen) }}>
                            <p className="absolute   w-8 h-8 border-[1px] rounded-full flex items-center justify-center text-blue-500">{destinyNumber}</p>
                        </div>
                    </div>

                    {/* <div className="flex border-l-[1px] w-fit h-[90px]"></div> */}

                    <div className="flex flex-col w-[150px]">
                        {destinyNumberDescription.map((word, index) => (
                            <p key={index}>{word}</p>
                        ))}
                    </div>


                </div >


                {destinyNymberPopupOpen ? <PopupContainer choosePopup={'destiny_number_popup'} setPopupOpen={setDestinyNymberPopupOpen} additionInfo={destinyNumber} /> : null}
            </>
        )
    }

    return (
        <section className="flex flex-col w-full">
            {topPhoto()}

            <div className="flex flex-col gap-5 p-2">

                {/* Ава Инфо Фио */}
                <div className="z-[10] flex gap-2 pl-3 pr-3">
                    {avatar()}

                    <div className="flex flex-col gap-1">
                        <div className="flex items-center">
                            {namesFirstLast()}
                            {profileInfo()}
                        </div>

                        <div className="flex gap-2 items-center">
                            {sendMessage()}
                            {editSubmitButton}
                            {editProfileButton()}
                            {telegramInProfile()}
                            {inviteFriendsButton()}
                        </div>
                    </div>
                </div>

                {/* Загорающиеся квадратики */}
                <div className="z-[0] w-[790px] lg:h-[440px] absolute flex items-center justify-center ">
                    {[...Array(19)].map((_, index) => (
                        <div className="" key={index}>
                            {[...Array(11)].map((_, index) => (
                                <div key={index} className="m-[1px] w-10 h-10 hover:opacity-10 hover:bg-slate-700 rounded-xl duration-500 ease-in-out" />
                            ))}
                        </div>
                    ))}
                </div>

                <div className="pt-6 flex flex-col items-center gap-5">
                    <div className="rounded-3xl w-[400px] h-fit  justify-center p-5 border_color">
                        {zodiacDescription()}
                        {zodiacElementDescription()}
                        {chineseZodiacDescription()}
                        {chineseZodiacElementDescription()}
                    </div>

                    <div className=" rounded-3xl w-[400px] h-fit  justify-center border_color">
                        {destinyNumberFunc()}
                    </div>

                    {miniPifagorView()}
                    {matrixPortrait()}
                </div>

                <div className="flex gap-3 justify-end">
                    {followersInfo()}
                    {profileLike()}
                    {profileVisits()}
                </div>

            </div>

            {/* {experiments()} */}

        </section>
    )
}

export default ProfileBaseDetails;
